import React from 'react';
import { Box, Typography, Grid, Paper, Link, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  Email as EmailIcon,
  Phone as PhoneIcon,
  LinkedIn as LinkedInIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';

const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const AnimatedCard = motion.create(Paper);

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: 2
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 0.5,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const TeamMemberImage = styled('img')({
  width: '190px',
  height: '190px',
  borderRadius: '50%',
  objectFit: 'cover',
  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
  transition: 'all 0.4s ease',
  marginBottom: '20px',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  }
});

const TeamMemberCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '2rem',
  background: COLORS.background.paper,
  borderRadius: '20px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  }
});

const ContactInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.8rem',
  marginTop: '1.5rem',
  width: '100%',
  '& a': {
    color: COLORS.text.secondary,
    textDecoration: 'none',
    transition: 'all 0.2s ease',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '0.9rem',
    '&:hover': {
      color: COLORS.primary.main,
      transform: 'translateX(3px)'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
      color: COLORS.primary.main
    }
  }
});

const FolkaSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    return {
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      teamMembers: Array.from(doc.querySelectorAll('.team-member')).map(member => ({
        name: member.querySelector('.member-name')?.textContent || '',
        role: member.querySelector('.member-role')?.textContent || '',
        email: member.querySelector('.member-email')?.textContent || '',
        phone: member.querySelector('.member-phone')?.textContent || '',
        linkedin: member.querySelector('.member-linkedin')?.getAttribute('href') || '',
        image: member.querySelector('.member-image')?.getAttribute('src') || ''
      }))
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content folka"
      id="folka"
      sx={{
        width: '100%'
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: 0,
          pb: { xs: 4, md: 6 },
          px: { xs: 2, md: 3 }
        }}
      >
        <SectionHeader title={menuItem?.title || 'FOLKA'} />

        <Box 
          component="header" 
          className="hero-section" 
          sx={{ 
            textAlign: 'center', 
            mb: 4,
            maxWidth: 900,
            mx: 'auto'
          }}
        >
          <Typography 
            variant="h2" 
            className="main-title"
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              color: COLORS.text.primary,
              mb: 2
            }}
          >
            {parsedContent.title}
          </Typography>
          <Typography 
            variant="h5"
            className="subtitle"
            sx={{ 
              color: COLORS.text.secondary,
              fontWeight: 400,
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            {parsedContent.subtitle}
          </Typography>
        </Box>

        <Grid 
          container 
          spacing={4}
          justifyContent="center"
          sx={{ 
            width: '100%',
            margin: 0,
            flexGrow: 0,
            padding: { xs: 2, md: 3 }
          }}
        >
          {parsedContent.teamMembers.map((member, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <TeamMemberCard>
                <TeamMemberImage
                  src={member.image}
                  alt={member.name}
                />
                <Typography variant="h5" sx={{ mt: 2, fontWeight: 600 }}>
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {member.role}
                </Typography>
                <ContactInfo>
                  {member.email && (
                    <Link href={`mailto:${member.email}`}>
                      <EmailIcon />
                      {member.email}
                    </Link>
                  )}
                  {member.phone && (
                    <Link href={`tel:${member.phone}`}>
                      <PhoneIcon />
                      {member.phone}
                    </Link>
                  )}
                  {member.linkedin && (
                    <Link href={member.linkedin} target="_blank">
                      <LinkedInIcon />
                      LinkedIn Profile
                    </Link>
                  )}
                </ContactInfo>
              </TeamMemberCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FolkaSection; 