import axios from '../utils/axiosConfig';
import cache, { updateCacheVersion } from '../utils/cacheUtils';

// Debug flag - set to false to disable console logs
const DEBUG = false;

// Cache keys
export const CACHE_KEYS = {
  MENU_ITEMS: 'menu_items',
  SITE_INFO: 'site_info',
  SITE_SETTINGS: 'site_settings',
  CONTENT_PREFIX: 'content_',
  PWA_SETTINGS: 'pwa_settings'
};

// Check if the user is offline
const isOffline = () => !navigator.onLine;

// Mock data for initial load when no cache exists
const MOCK_DATA = {
  menu_items: {
    success: true,
    data: [
      {
        id: 1,
        title: 'Hjem',
        slug: 'hjem',
        content: '<h1>Velkommen til Pebbles</h1><p>Vi bygger teknologi med hjertet!</p>',
        order_index: 1,
        parent_id: null
      },
      {
        id: 2,
        title: 'Om oss',
        slug: 'om-oss',
        content: '<h1>Om Pebbles</h1><p>Vi er et dedikert team som skaper innovative løsninger.</p>',
        order_index: 2,
        parent_id: null
      }
    ]
  },
  site_info: {
    success: true,
    data: {
      company_name: 'Pebbles',
      email: 'hei@pebbles.no',
      phone: '+47 123 45 678',
      address: 'Oslo, Norge'
    }
  },
  site_settings: {
    success: true,
    data: {
      site_title: 'Pebbles CMS',
      meta_description: 'Vi bygger teknologi med hjertet!'
    }
  }
};

// Helper function to get fallback data
const getFallbackData = (key) => {
  // Try to get from cache first (even if expired)
  const cachedData = localStorage.getItem(`cache_${key}`);
  if (cachedData) {
    try {
      return JSON.parse(cachedData).data;
    } catch (e) {
      console.warn('Failed to parse cached data:', e);
    }
  }
  
  // If no cache, use mock data
  return MOCK_DATA[key] || { success: false, error: 'No data available' };
};

const apiService = {
  // Get menu items with caching
  getMenuItems: async (forceRefresh = false) => {
    const cacheKey = CACHE_KEYS.MENU_ITEMS;
    
    // Return cached data if available and not forcing refresh
    if (!forceRefresh) {
      const cachedData = cache.get(cacheKey);
      if (cachedData) {
        if (DEBUG) console.log('Using cached menu items');
        return cachedData;
      }
    }
    
    // If offline, immediately use fallback data
    if (isOffline()) {
      console.log('User is offline, using cached menu items');
      const fallbackData = getFallbackData(cacheKey);
      return { ...fallbackData, offline: true };
    }
    
    try {
      const response = await axios.get('/api/menu-items.php');
      if (response.data.success) {
        // Cache the successful response
        cache.set(cacheKey, response.data);
        return response.data;
      }
      
      // Handle API response with offline flag
      if (response.data.offline) {
        const fallbackData = getFallbackData(cacheKey);
        return { ...fallbackData, offline: true };
      }
      
      throw new Error(response.data.error || 'Failed to fetch menu items');
    } catch (error) {
      console.error('Error fetching menu items:', error);
      
      // Return cached data as fallback even if expired
      const fallbackData = getFallbackData(cacheKey);
      if (DEBUG) console.log('Using fallback data for menu items');
      return { ...fallbackData, offline: true };
    }
  },
  
  // Get site info with caching
  getSiteInfo: async (forceRefresh = false) => {
    const cacheKey = CACHE_KEYS.SITE_INFO;
    
    if (!forceRefresh) {
      const cachedData = cache.get(cacheKey);
      if (cachedData) {
        if (DEBUG) console.log('Using cached site info');
        return cachedData;
      }
    }
    
    // If offline, immediately use fallback data
    if (isOffline()) {
      console.log('User is offline, using cached site info');
      const fallbackData = getFallbackData(cacheKey);
      return { ...fallbackData, offline: true };
    }
    
    try {
      const response = await axios.get('/api/site-info.php');
      if (response.data.success) {
        cache.set(cacheKey, response.data);
        return response.data;
      }
      
      // Handle API response with offline flag
      if (response.data.offline) {
        const fallbackData = getFallbackData(cacheKey);
        return { ...fallbackData, offline: true };
      }
      
      throw new Error(response.data.error || 'Failed to fetch site info');
    } catch (error) {
      console.error('Error fetching site info:', error);
      
      const fallbackData = getFallbackData(cacheKey);
      if (DEBUG) console.log('Using fallback data for site info');
      return { ...fallbackData, offline: true };
    }
  },
  
  // Get site settings with caching
  getSiteSettings: async (forceRefresh = false) => {
    const cacheKey = CACHE_KEYS.SITE_SETTINGS;
    
    if (!forceRefresh) {
      const cachedData = cache.get(cacheKey);
      if (cachedData) {
        if (DEBUG) console.log('Using cached site settings');
        return cachedData;
      }
    }
    
    // If offline, immediately use fallback data
    if (isOffline()) {
      console.log('User is offline, using cached site settings');
      const fallbackData = getFallbackData(cacheKey);
      return { ...fallbackData, offline: true };
    }
    
    try {
      const response = await axios.get('/api/site-settings.php');
      if (response.data.success) {
        // Check if cache version has changed
        if (response.data.cache_version) {
          updateCacheVersion(response.data.cache_version);
        }
        
        cache.set(cacheKey, response.data);
        return response.data;
      }
      
      // Handle API response with offline flag
      if (response.data.offline) {
        const fallbackData = getFallbackData(cacheKey);
        return { ...fallbackData, offline: true };
      }
      
      throw new Error(response.data.error || 'Failed to fetch site settings');
    } catch (error) {
      console.error('Error fetching site settings:', error);
      
      const fallbackData = getFallbackData(cacheKey);
      if (DEBUG) console.log('Using fallback data for site settings');
      return { ...fallbackData, offline: true };
    }
  },
  
  // Get PWA settings with caching
  getPwaSettings: async (forceRefresh = false) => {
    const cacheKey = CACHE_KEYS.PWA_SETTINGS;
    
    if (!forceRefresh) {
      const cachedData = cache.get(cacheKey);
      if (cachedData) {
        if (DEBUG) console.log('Using cached PWA settings');
        return cachedData;
      }
    }
    
    // If offline, immediately use fallback data with enable_pwa_install = true as default
    if (isOffline()) {
      console.log('User is offline, using default PWA settings');
      return { 
        success: true, 
        offline: true,
        data: { 
          enable_pwa_install: true 
        } 
      };
    }
    
    try {
      const response = await axios.get('/api/pwa-settings.php');
      if (response.data.success) {
        cache.set(cacheKey, response.data);
        return response.data;
      }
      
      // Handle API response with offline flag
      if (response.data.offline) {
        return { 
          success: true, 
          offline: true,
          data: { 
            enable_pwa_install: true 
          } 
        };
      }
      
      throw new Error(response.data.error || 'Failed to fetch PWA settings');
    } catch (error) {
      console.error('Error fetching PWA settings:', error);
      
      // Default to enabled if there's an error
      return { 
        success: true, 
        offline: true,
        data: { 
          enable_pwa_install: true 
        } 
      };
    }
  },
  
  // Get content for a specific slug with caching
  getContent: async (slug, forceRefresh = false) => {
    const cacheKey = `${CACHE_KEYS.CONTENT_PREFIX}${slug}`;
    
    if (!forceRefresh) {
      const cachedData = cache.get(cacheKey);
      if (cachedData) {
        if (DEBUG) console.log(`Using cached content for ${slug}`);
        return cachedData;
      }
    }
    
    // If offline, immediately use fallback data
    if (isOffline()) {
      console.log(`User is offline, attempting to use cached content for ${slug}`);
      // Try to get from cache even if expired
      const cachedData = localStorage.getItem(`cache_${cacheKey}`);
      if (cachedData) {
        try {
          const parsedData = JSON.parse(cachedData);
          return { ...parsedData.data, offline: true };
        } catch (e) {
          console.warn('Failed to parse cached content data:', e);
        }
      }
      
      // If no cache for this specific content, return a generic offline message
      return {
        success: false,
        offline: true,
        error: `You are currently offline. Content for "${slug}" is not available offline.`
      };
    }
    
    try {
      const response = await axios.get(`/api/get-page.php?slug=${slug}`);
      if (response.data.success) {
        cache.set(cacheKey, response.data);
        return response.data;
      }
      
      // Handle API response with offline flag
      if (response.data.offline) {
        // Try to get from cache even if expired
        const cachedData = localStorage.getItem(`cache_${cacheKey}`);
        if (cachedData) {
          try {
            const parsedData = JSON.parse(cachedData);
            return { ...parsedData.data, offline: true };
          } catch (e) {
            console.warn('Failed to parse cached content data:', e);
          }
        }
      }
      
      throw new Error(response.data.error || 'Failed to fetch content');
    } catch (error) {
      console.error(`Error fetching content for ${slug}:`, error);
      
      // For content, we don't have mock data for each slug
      // So we'll just return a generic error message
      return {
        success: false,
        offline: navigator.onLine ? false : true,
        error: `Failed to load content for ${slug}. ${navigator.onLine ? 'Please try again later.' : 'You are currently offline.'}`
      };
    }
  },
  
  // Clear all cache (useful after content updates)
  clearCache: () => {
    cache.clearAll();
    if (DEBUG) console.log('Cache cleared successfully');
    return true;
  }
};

export default apiService; 