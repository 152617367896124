import React from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  Work as WorkIcon,
  Business as BusinessIcon,
  CalendarToday as CalendarIcon,
  TrendingUp as TrendingIcon,
  Chat as ChatIcon,
  MonetizationOn as MoneyIcon,
  Support as SupportIcon,
  Group as NetworkIcon
} from '@mui/icons-material';

const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

const AnimatedCard = motion.create(Paper);
const AnimatedBox = motion.create(Box);

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: 2
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 0.5,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const OpportunityCard = ({ iconType, title, description }) => {
  const IconComponent = {
    'work': WorkIcon,
    'business': BusinessIcon,
    'calendar': CalendarIcon,
    'trending': TrendingIcon
  }[iconType] || WorkIcon;

  return (
    <AnimatedCard
      elevation={0}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      variants={fadeInUp}
      sx={{
        p: 3,
        height: '100%',
        borderRadius: 2,
        backgroundColor: 'white',
        boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-6px)',
          boxShadow: '0 12px 30px rgba(0,0,0,0.08)',
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          },
          '&::before': {
            opacity: 1
          }
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '4px',
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          opacity: 0,
          transition: 'opacity 0.3s ease'
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 36,
          color: COLORS.primary.main,
          mb: 2,
          transition: 'all 0.3s ease'
        }} 
      />
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 600,
          color: COLORS.text.primary,
          mb: 1.5,
          fontSize: '1.1rem'
        }}
      >
        {title}
      </Typography>
      <Typography 
        variant="body2"
        sx={{ 
          color: COLORS.text.secondary,
          lineHeight: 1.5
        }}
      >
        {description}
      </Typography>
    </AnimatedCard>
  );
};

const FeatureItem = ({ icon, title, description }) => {
  const getIconByTitle = (title) => {
    const titleLower = title.toLowerCase();
    if (titleLower.includes('kundekontakt') || titleLower.includes('dialog')) return ChatIcon;
    if (titleLower.includes('vilkår') || titleLower.includes('pris')) return MoneyIcon;
    if (titleLower.includes('støtte') || titleLower.includes('oppfølging')) return SupportIcon;
    if (titleLower.includes('nettverk') || titleLower.includes('samarbeid')) return NetworkIcon;
    return ChatIcon;
  };

  const IconComponent = getIconByTitle(title);

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        gap: 3,
        mb: 4,
        '&:last-child': { mb: 0 },
        '&:hover': {
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          }
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 40,
          color: COLORS.primary.main,
          mt: 1,
          flexShrink: 0,
          transition: 'all 0.3s ease'
        }} 
      />
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 1,
            color: COLORS.text.primary,
            fontWeight: 600
          }}
        >
          {title}
        </Typography>
        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const OpdragSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    // Map emoji to icon types
    const emojiToIcon = {
      '💼': 'work',
      '🏢': 'business',
      '📅': 'calendar',
      '📈': 'trending',
      '💬': 'chat',
      '💰': 'money',
      '🤝': 'support',
      '🔄': 'network'
    };
    
    return {
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      opportunities: Array.from(doc.querySelectorAll('.opportunity-card')).map(card => ({
        iconType: emojiToIcon[card.querySelector('.icon')?.textContent?.trim()] || 'work',
        title: card.querySelector('.opportunity-title')?.textContent || '',
        description: card.querySelector('.opportunity-content p')?.textContent || ''
      })),
      features: {
        title: doc.querySelector('.features h2')?.textContent || 'Hvorfor velge oss?',
        items: Array.from(doc.querySelectorAll('.feature-item')).map(item => ({
          title: item.querySelector('h4')?.textContent || '',
          description: item.querySelector('p')?.textContent || ''
        }))
      }
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content oppdrag"
      id="oppdrag"
      sx={{
        width: '100%',
        margin: 0,
        padding: 0
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          pt: 0,
          pb: { xs: 4, md: 6 },
          px: { xs: 2, md: 3 }
        }}
      >
        <SectionHeader title={menuItem?.title || 'OPPDRAG'} />

        <Box component="header" sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="h2" 
            className="main-title"
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              color: COLORS.text.primary,
              mb: 2,
              maxWidth: '900px',
              mx: 'auto'
            }}
          >
            {parsedContent.title}
          </Typography>
          <Typography 
            variant="h5"
            className="subtitle"
            sx={{ 
              color: COLORS.text.secondary,
              fontWeight: 400,
              maxWidth: '800px',
              mx: 'auto',
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            {parsedContent.subtitle}
          </Typography>
        </Box>

        <Box component="section" className="opportunities" sx={{ mb: 10 }}>
          <Grid container spacing={3}>
            {parsedContent.opportunities.map((opportunity, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <OpportunityCard {...opportunity} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default OpdragSection; 