import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { Chat as ChatIcon, MonetizationOn as MoneyIcon, Support as SupportIcon, Group as NetworkIcon, Verified as VerifiedIcon } from '@mui/icons-material';

const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

const AnimatedBox = motion.create(Box);

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: { xs: 4, md: 6 }
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 1,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const FeatureItem = ({ icon, title, description }) => {
  const getIconByTitle = (title) => {
    const titleLower = title.toLowerCase();
    if (titleLower.includes('kundekontakt') || titleLower.includes('dialog')) return ChatIcon;
    if (titleLower.includes('vilkår') || titleLower.includes('pris')) return MoneyIcon;
    if (titleLower.includes('støtte') || titleLower.includes('oppfølging')) return SupportIcon;
    if (titleLower.includes('nettverk') || titleLower.includes('samarbeid')) return NetworkIcon;
    if (titleLower.includes('fordeler')) return VerifiedIcon;
    return ChatIcon;
  };

  const IconComponent = getIconByTitle(title);

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        gap: 3,
        mb: 4,
        '&:last-child': { mb: 0 },
        '&:hover': {
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          }
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 40,
          color: COLORS.primary.main,
          mt: 1,
          flexShrink: 0,
          transition: 'all 0.3s ease'
        }} 
      />
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 1,
            color: COLORS.text.primary,
            fontWeight: 600
          }}
        >
          {title}
        </Typography>
        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const HvorforVelgeOssSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    return {
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      features: {
        title: doc.querySelector('.features-title')?.textContent || '',
        items: Array.from(doc.querySelectorAll('.feature-item')).map((item) => ({
          title: item.querySelector('h4')?.textContent || '',
          description: item.querySelector('p')?.textContent || ''
        }))
      },
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content hvorfor-velge-oss"
      id="hvorfor-velge-oss"
      sx={{
        width: '100%',
        mb: { xs: 6, md: 8 }
      }}
    >
      {/* Section Title */}
      <SectionHeader title={menuItem?.title || 'HVORFOR VELGE OSS?'} />

      {/* Hero Section */}
      <Box component="header" sx={{ textAlign: 'center', mb: 8 }}>
        <Typography 
          variant="h2" 
          className="main-title"
          sx={{ 
            fontSize: { xs: '2rem', md: '3rem' },
            fontWeight: 700,
            color: COLORS.text.primary,
            mb: 2,
            maxWidth: '900px',
            mx: 'auto'
          }}
        >
          {parsedContent.title}
        </Typography>
        <Typography 
          variant="h5"
          className="subtitle"
          sx={{ 
            color: COLORS.text.secondary,
            fontWeight: 400,
            maxWidth: '800px',
            mx: 'auto',
            fontSize: { xs: '1.1rem', md: '1.25rem' }
          }}
        >
          {parsedContent.subtitle}
        </Typography>
      </Box>

      <AnimatedBox
        component="section"
        className="features"
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        variants={staggerChildren}
        sx={{ 
          maxWidth: 900,
          mx: 'auto',
          p: 4,
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.03)'
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 4,
            textAlign: 'center',
            color: COLORS.text.primary,
            fontWeight: 600,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '40px',
              height: '3px',
              background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
              borderRadius: '2px'
            }
          }}
        >
          {parsedContent.features.title}
        </Typography>
        <Box>
          {parsedContent.features.items.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </Box>
      </AnimatedBox>
    </Box>
  );
};

export default HvorforVelgeOssSection; 