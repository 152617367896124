import axios from 'axios';

// Determine the environment and set the appropriate base URL
const isDevelopment = process.env.NODE_ENV === 'development';
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// Debug flag - set to false to disable console logs
const DEBUG = false;

// Set the base URL from environment variable or use a default
// In development mode on localhost, use localhost:8000
// Otherwise, use the production URL or environment variable
const apiUrl = (isDevelopment && isLocalhost) 
  ? 'http://localhost:8000' 
  : (process.env.REACT_APP_API_URL || 'https://pebbles.no');

// Only log in development mode with debug enabled
if (isDevelopment && DEBUG) {
  console.log(`Using API URL: ${apiUrl}`);
}

// Configure axios defaults
axios.defaults.baseURL = apiUrl;
axios.defaults.timeout = 15000; // 15 seconds timeout
axios.defaults.headers.common['Accept'] = 'application/json';

// Add request interceptor for debugging
axios.interceptors.request.use(
  config => {
    // Only log in development mode with debug enabled
    if (isDevelopment && DEBUG) {
      console.log(`API Request: ${config.method.toUpperCase()} ${config.url}`);
    }
    return config;
  },
  error => {
    // Always log errors
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
axios.interceptors.response.use(
  response => {
    // Only log in development mode with debug enabled
    if (isDevelopment && DEBUG) {
      console.log(`API Response: ${response.status} ${response.config.url}`);
    }
    return response;
  },
  error => {
    // Always log errors
    if (error.response) {
      console.error(`API Error ${error.response.status}: ${error.response.config.url}`, error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axios; 