import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate, startTransition } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import Page from './components/public/Page';
import MenuManager from './components/admin/MenuManager';
import Settings from './components/admin/Settings';
import Login from './components/admin/Login';
import ProtectedRoute from './components/admin/ProtectedRoute';
import Layout from './components/public/Layout';
import AdminLayout from './components/admin/AdminLayout';
import Overview from './components/admin/Overview';
import { COLORS } from './utils/colors';
import MaintenancePage from './components/public/MaintenancePage';
import ErrorBoundary from './components/ErrorBoundary';
import HydrateFallback from './components/common/HydrateFallback';
import { createEnhancedRouter } from './utils/routerConfig';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary.main,
      light: COLORS.primary.light,
      dark: COLORS.primary.dark
    },
    secondary: {
      main: COLORS.secondary.main,
      light: COLORS.secondary.light,
      dark: COLORS.secondary.dark
    },
    text: {
      primary: COLORS.text.primary,
      secondary: COLORS.text.secondary
    },
    background: {
      default: COLORS.background.default,
      paper: COLORS.background.paper
    }
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    },
    h2: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    },
    h3: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    },
    h4: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    },
    h5: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    },
    h6: {
      fontFamily: '"Poppins", "Inter", sans-serif',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
          fontWeight: 500,
          fontSize: '0.875rem',
          padding: '6px 16px',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
          }
        },
        containedPrimary: {
          backgroundColor: COLORS.primary.main,
          '&:hover': {
            backgroundColor: COLORS.primary.dark
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          color: '#fff',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          '&:hover': {
            boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
          }
        },
        elevation1: {
          boxShadow: '0 2px 10px rgba(0,0,0,0.03)',
          borderRadius: 8,
          border: `1px solid ${COLORS.tertiary.main}`,
          transition: 'all 0.3s ease'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: `1px solid ${COLORS.tertiary.main}`,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 8px 24px rgba(0,0,0,0.05)'
          }
        }
      }
    }
  }
});

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
const BYPASS_KEY = 'your_secret_key';

const canBypassMaintenance = () => {
  try {
    const params = new URLSearchParams(window.location.search);
    const bypassFromUrl = params.get('bypass') === BYPASS_KEY;
    const bypassFromStorage = localStorage.getItem('bypass_maintenance') === BYPASS_KEY;
    
    if (bypassFromUrl) {
      localStorage.setItem('bypass_maintenance', BYPASS_KEY);
    }
    
    console.log('Maintenance bypass:', bypassFromUrl || bypassFromStorage);
    return bypassFromUrl || bypassFromStorage;
  } catch (error) {
    console.error('Bypass check error:', error);
    return false;
  }
};

// Define routes
const routes = [
  {
    path: '/',
    element: MAINTENANCE_MODE && !canBypassMaintenance() ? (
      <MaintenancePage />
    ) : (
      <Layout><Page /></Layout>
    ),
  },
  {
    path: '/kontrollpanel',
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: '',
        element: (
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="dashboard" replace />
          },
          {
            path: 'dashboard',
            element: <Overview />
          },
          {
            path: 'menu',
            element: <MenuManager />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          {
            path: '*',
            element: <Navigate to="dashboard" replace />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  }
];

// Create router with future flags properly applied
const router = createEnhancedRouter(routes, createBrowserRouter);

function App() {
  return (
    <ErrorBoundary>
      <React.StrictMode>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider 
              router={router}
              fallbackElement={<HydrateFallback />}
              future={{
                v7_startTransition: true
              }}
            />
          </ThemeProvider>
        </HelmetProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
}

export default App;
