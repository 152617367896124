import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import { COLORS } from '../../utils/colors';
import CachedIcon from '@mui/icons-material/Cached';
import apiService from '../../services/apiService';

function Overview() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleClearCache = () => {
    try {
      apiService.clearCache();
      setSnackbarMessage('Cache cleared successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error clearing cache:', error);
      setSnackbarMessage('Failed to clear cache');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ color: COLORS.text.primary, fontWeight: 500 }}>
          Velkommen til Pebbles Admin
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CachedIcon />}
          onClick={handleClearCache}
          sx={{ ml: 2 }}
        >
          Clear Content Cache
        </Button>
      </Box>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 4, backgroundColor: '#fff' }}>
            <Typography variant="h5" gutterBottom sx={{ color: COLORS.primary.main, mb: 2, fontWeight: 500 }}>
              Administrer din nettside
            </Typography>
            <Typography variant="body1" sx={{ color: COLORS.text.secondary, mb: 3, lineHeight: 1.6 }}>
              Her kan du enkelt administrere innholdet på Pebbles-nettstedet. Bruk menyen til venstre for å navigere mellom de ulike funksjonene.
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%', backgroundColor: COLORS.secondary.light }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom sx={{ color: COLORS.text.primary }}>
                        Menybehandling
                      </Typography>
                      <Typography variant="body2" sx={{ color: COLORS.text.secondary }}>
                        Rediger menystruktur og innhold for nettstedet ditt. Legg til, endre eller fjern menyelementer.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%', backgroundColor: COLORS.secondary.light }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom sx={{ color: COLORS.text.primary }}>
                        Innstillinger
                      </Typography>
                      <Typography variant="body2" sx={{ color: COLORS.text.secondary }}>
                        Administrer nettstedets generelle innstillinger, kontaktinformasjon og metadata.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ height: '100%', backgroundColor: COLORS.secondary.light }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom sx={{ color: COLORS.text.primary }}>
                        Cache Management
                      </Typography>
                      <Typography variant="body2" sx={{ color: COLORS.text.secondary, mb: 2 }}>
                        Clear the content cache when you've made updates to ensure all users see the latest content.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<CachedIcon />}
                        onClick={handleClearCache}
                      >
                        Clear Cache
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Overview;
