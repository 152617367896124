import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { 
  AutoAwesome as KonseptIcon,
  Groups as FagligIcon,
  AccountBalance as OkonomiskIcon,
  Favorite as LidenskapIcon,
  People as FolkaIcon,
  Work as OpdragIcon,
  Stars as OptimalIcon,
  Star as StarIcon,
  Engineering as EngineeringIcon
} from '@mui/icons-material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import apiService from '../../services/apiService';

const MenuContainer = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== 'mobile' && 
    prop !== 'collapsed'
})(({ mobile, collapsed }) => ({
  position: mobile ? 'relative' : 'fixed',
  left: mobile ? 'auto' : 0,
  top: mobile ? 'auto' : '50%',
  transform: mobile ? 'none' : 'translateY(-50%)',
  width: mobile ? '100%' : (collapsed ? '60px' : '240px'),
  maxHeight: mobile ? 'none' : '90vh',
  background: COLORS.background.paper,
  boxShadow: mobile ? 'none' : '4px 0 24px rgba(0,0,0,0.05)',
  borderRadius: mobile ? 0 : '0 12px 12px 0',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  zIndex: mobile ? 1200 : 1000,
  display: 'block !important',
  '@media (min-width: 1920px)': {
    left: mobile ? 0 : 'calc((100vw - 1920px) / 2)',
  },
  '@media (max-width: 1919px)': {
    left: 0
  }
}));

const MenuHeader = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== 'mobile' && 
    prop !== 'collapsed'
})(({ mobile, collapsed }) => ({
  padding: mobile ? '16px 20px' : (typeof collapsed !== 'undefined' ? (collapsed ? '8px 0' : '12px 24px') : '12px 24px'),
  borderBottom: `1px solid ${COLORS.tertiary.main}`,
  backgroundColor: COLORS.secondary.main,
  display: mobile ? 'none' : 'block',
  borderRadius: 0
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => 
    prop !== 'isActive' && 
    prop !== 'mobile' &&
    prop !== 'collapsed'
})(({ isActive, mobile, collapsed }) => ({
  position: 'relative',
  color: isActive ? COLORS.primary.main : COLORS.text.secondary,
  textDecoration: 'none',
  padding: mobile ? '12px 20px' : (collapsed ? '12px' : '12px 16px'),
  justifyContent: collapsed ? 'center' : 'flex-start',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  minHeight: '48px',
  whiteSpace: 'normal',
  lineHeight: 1.2,
  '& .menu-icon': {
    fontSize: collapsed ? '24px' : '20px',
    transition: 'all 0.3s ease',
    opacity: 0.7,
    flexShrink: 0,
    marginTop: '2px'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '3px',
    background: COLORS.primary.main,
    transform: isActive ? 'scaleY(1)' : 'scaleY(0)',
    transition: 'transform 0.3s ease'
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    background: COLORS.tertiary.light,
    opacity: isActive ? 0.5 : 0,
    transition: 'opacity 0.3s ease',
    zIndex: 0
  },
  '&:hover': {
    color: COLORS.primary.main,
    '&:before': {
      transform: 'scaleY(1)'
    },
    '&:after': {
      opacity: 0.3
    },
    '& .menu-icon': {
      opacity: 1,
      transform: 'scale(1.1)'
    }
  }
}));

const MenuBullet = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'mobile' && prop !== 'collapsed'
})(({ isActive, mobile, collapsed }) => ({
  fontSize: mobile ? '6px' : (collapsed ? '14px' : '8px'),
  color: isActive ? COLORS.primary.main : COLORS.text.secondary,
  transition: 'all 0.3s ease',
  opacity: 0.8,
  fontWeight: collapsed ? 500 : 'normal',
  width: collapsed ? '20px' : 'auto',
  textAlign: 'center'
}));

const getMenuIcon = (slug) => {
  const icons = {
    'konseptet': <KonseptIcon />,
    'frivillig-faglig-fellesskap': <FagligIcon />,
    'okonomisk-modell': <OkonomiskIcon />,
    'var-lidenskap': <LidenskapIcon />,
    'folka': <FolkaIcon />,
    'oppdrag': <OpdragIcon />,
    'optimal-konsulenttilvaerelse': <OptimalIcon />,
    'hvorfor-velge-oss': <CheckCircleIcon />,
    'var-ekspertise': <EngineeringIcon />
  };
  return icons[slug] || <FiberManualRecordIcon />;
};

function NavMenu({ mobile, onClose }) {
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        // Check if the user is online
        if (!navigator.onLine) {
          console.log('User is offline, attempting to use cached data');
        }
        
        // Use the cached API service
        const response = await apiService.getMenuItems();
        
        if (response.success) {
          setMenuItems(response.data);
        } else {
          console.error('Error fetching menu items:', response.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching menu items:', error);
        
        // Log more detailed error information
        if (error.response) {
          console.error('Server responded with:', error.response.status, error.response.data);
        } else if (error.request) {
          console.error('No response received from server');
        } else {
          console.error('Error message:', error.message);
        }
      }
    };

    fetchMenuItems();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section-content');
      let currentSection = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.scrollY >= sectionTop - 200 && 
            window.scrollY < sectionTop + sectionHeight - 200) {
          currentSection = section.id;
        }
      });

      if (currentSection === 'okonomisk-modell') {
        setActiveSection('okonomisk-modell');
      } else {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleItemClick = (slug) => {
    if (mobile && onClose) {
      onClose();
    }
    
    const element = document.getElementById(slug);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
      window.history.pushState(null, '', `#${slug}`);
    }
  };

  return (
    <MenuContainer 
      mobile={mobile || undefined} 
      collapsed={isCollapsed || undefined}
    >
      <MenuHeader 
        mobile={mobile || undefined} 
        collapsed={isCollapsed || undefined}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: isCollapsed ? 'center' : 'space-between'
        }}>
          {!isCollapsed && (
            <Typography
              variant="overline"
              sx={{
                fontWeight: 600,
                color: COLORS.primary.main,
                letterSpacing: 1.2,
                fontSize: '0.75rem'
              }}
            >
              MENY
            </Typography>
          )}
          
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            sx={{
              color: COLORS.primary.main,
              padding: '2px',
              margin: isCollapsed ? '0' : undefined,
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
      </MenuHeader>
      <List component="nav" sx={{ width: '100%', py: mobile ? 0 : 1 }}>
        {menuItems.map((item) => {
          const isActive = activeSection === item.slug;
          
          return (
            <StyledListItem
              key={item.id}
              onClick={() => handleItemClick(item.slug)}
              isActive={isActive}
              mobile={mobile || undefined}
              collapsed={isCollapsed || undefined}
              sx={{ cursor: 'pointer' }}
            >
              <Box className="menu-icon" sx={{ position: 'relative', zIndex: 1 }}>
                {getMenuIcon(item.slug)}
              </Box>
              {!isCollapsed && (
                <ListItemText 
                  primary={item.title} 
                  sx={{
                    margin: 0,
                    position: 'relative',
                    zIndex: 1,
                    flex: '1 1 auto',
                    '& .MuiListItemText-primary': {
                      fontSize: mobile ? '0.8125rem' : '0.75rem',
                      fontWeight: isActive ? 500 : 400,
                      color: 'inherit',
                      letterSpacing: 'normal',
                      transition: 'all 0.3s ease',
                      lineHeight: 1.2
                    }
                  }}
                />
              )}
            </StyledListItem>
          );
        })}
      </List>
    </MenuContainer>
  );
}

export default NavMenu;
