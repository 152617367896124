import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
  FormControlLabel,
  Switch
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';

function Settings() {
  const [settings, setSettings] = useState({
    site_title: '',
    meta_description: ''
  });

  const [siteInfo, setSiteInfo] = useState({
    email: '',
    phone: '',
    address: ''
  });

  const [pwaSettings, setPwaSettings] = useState({
    enable_pwa_install: true
  });

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingInfo, setSavingInfo] = useState(false);
  const [savingPwa, setSavingPwa] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch site settings
      const settingsResponse = await axios.get('/api/site-settings.php');
      if (settingsResponse.data.success) {
        setSettings(settingsResponse.data.data);
      }

      // Fetch site info
      const infoResponse = await axios.get('/api/site-info.php');
      if (infoResponse.data.success) {
        setSiteInfo(infoResponse.data.data);
      }

      // Fetch PWA settings
      const pwaResponse = await axios.get('/api/pwa-settings.php');
      if (pwaResponse.data.success) {
        setPwaSettings(pwaResponse.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showSnackbar('Failed to load settings', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSettingsChange = (field) => (event) => {
    setSettings(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleInfoChange = (field) => (event) => {
    setSiteInfo(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handlePwaChange = (field) => (event) => {
    setPwaSettings(prev => ({
      ...prev,
      [field]: event.target.checked
    }));
  };

  const handleSaveSettings = async () => {
    setSaving(true);
    try {
      const response = await axios.post('/api/site-settings.php', settings);
      if (response.data.success) {
        showSnackbar('Settings saved successfully');
      } else {
        throw new Error(response.data.error || 'Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      showSnackbar('Failed to save settings', 'error');
    } finally {
      setSaving(false);
    }
  };

  const handleSaveInfo = async () => {
    setSavingInfo(true);
    try {
      const response = await axios.post('/api/site-info.php', siteInfo);
      if (response.data.success) {
        showSnackbar('Contact information saved successfully');
      } else {
        throw new Error(response.data.error || 'Failed to save contact information');
      }
    } catch (error) {
      console.error('Error saving contact information:', error);
      showSnackbar('Failed to save contact information', 'error');
    } finally {
      setSavingInfo(false);
    }
  };

  const handleSavePwaSettings = async () => {
    setSavingPwa(true);
    try {
      // Log the data being sent
      console.log('Sending PWA settings:', pwaSettings);
      
      // Use a more direct approach with explicit data formatting
      // Convert the boolean value to a boolean explicitly to handle false values correctly
      const formattedData = {
        enable_pwa_install: Boolean(pwaSettings.enable_pwa_install)
      };
      
      console.log('Formatted data:', formattedData);
      
      // Add a timestamp to prevent caching issues
      const timestamp = new Date().getTime();
      
      // Get the authentication token from localStorage
      // Use 'token' instead of 'auth_token' to match how it's stored in Login.js
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }
      
      // Make the request with explicit error handling
      const response = await axios({
        method: 'POST',
        url: `/api/pwa-settings.php?_t=${timestamp}`,
        data: formattedData,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('PWA settings response:', response);
      
      if (response.data && response.data.success) {
        showSnackbar('PWA settings saved successfully');
        
        // Force refresh PWA settings in cache
        try {
          await axios.get(`/api/pwa-settings.php?_t=${timestamp}`, { 
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            }
          });
        } catch (refreshError) {
          console.warn('Failed to refresh PWA settings cache:', refreshError);
        }
      } else {
        const errorMsg = response.data && response.data.error 
          ? response.data.error 
          : 'Failed to save PWA settings';
        throw new Error(errorMsg);
      }
    } catch (error) {
      console.error('Error saving PWA settings:', error);
      showSnackbar('Failed to save PWA settings', 'error');
    } finally {
      setSavingPwa(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Site Settings</Typography>
      
      {/* Meta Settings */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>Meta Information</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Site Title"
              value={settings.site_title}
              onChange={handleSettingsChange('site_title')}
              placeholder="Pebbles | Profesjonell Bedriftsrådgivning og Konsulentvirksomhet i Norge"
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Meta Description"
              value={settings.meta_description}
              onChange={handleSettingsChange('meta_description')}
              placeholder="Pebbles tilbyr skreddersydd bedriftsrådgivning med fokus på økonomisk modellering, frivillig faglig fellesskap og optimal konsulenttilværelse. Vi hjelper din bedrift med å nå sitt fulle potensial."
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSaveSettings}
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {saving ? 'Saving...' : 'Save Settings'}
          </Button>
        </Box>
      </Paper>

      {/* PWA Settings */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>Progressive Web App Settings</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <GetAppIcon sx={{ mr: 1, color: COLORS.primary.main }} />
          <Typography variant="body1">
            Control the mobile app installation prompt
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={pwaSettings.enable_pwa_install}
                  onChange={handlePwaChange('enable_pwa_install')}
                  color="primary"
                />
              }
              label="Enable app installation prompt on mobile devices"
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              When enabled, mobile users will be prompted to install the website as an app on their home screen.
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSavePwaSettings}
            disabled={savingPwa}
            startIcon={savingPwa ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {savingPwa ? 'Saving...' : 'Save PWA Settings'}
          </Button>
        </Box>
      </Paper>

      {/* Contact Information */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>Contact Information</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={siteInfo.email}
              onChange={handleInfoChange('email')}
              type="email"
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone"
              value={siteInfo.phone}
              onChange={handleInfoChange('phone')}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              value={siteInfo.address}
              onChange={handleInfoChange('address')}
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSaveInfo}
            disabled={savingInfo}
            startIcon={savingInfo ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {savingInfo ? 'Saving...' : 'Save Contact Info'}
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Settings;
