import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';

function MathCaptcha({ onValidate }) {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [answer, setAnswer] = useState('');
  const [operator, setOperator] = useState('+');

  const generateProblem = () => {
    const newNum1 = Math.floor(Math.random() * 10) + 1;
    const newNum2 = Math.floor(Math.random() * 10) + 1;
    const operators = ['+', '-', 'x'];
    const newOperator = operators[Math.floor(Math.random() * operators.length)];
    
    setNum1(newNum1);
    setNum2(newNum2);
    setOperator(newOperator);
    setAnswer('');
  };

  useEffect(() => {
    generateProblem();
  }, []);

  const handleAnswerChange = (e) => {
    const value = e.target.value;
    setAnswer(value);

    let correctAnswer;
    switch (operator) {
      case '+':
        correctAnswer = num1 + num2;
        break;
      case '-':
        correctAnswer = num1 - num2;
        break;
      case 'x':
        correctAnswer = num1 * num2;
        break;
      default:
        correctAnswer = 0;
    }

    onValidate(parseInt(value) === correctAnswer);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography 
        variant="body2" 
        sx={{ 
          mb: 1, 
          color: COLORS.text.secondary,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          fontSize: '1rem'
        }}
      >
        <span>{num1}</span>
        <span>{operator}</span>
        <span>{num2}</span>
        <span>=</span>
      </Typography>
      <TextField
        fullWidth
        label="Løs regnestykket"
        type="number"
        value={answer}
        onChange={handleAnswerChange}
        required
        sx={{
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: COLORS.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: COLORS.primary.main,
            }
          }
        }}
      />
    </Box>
  );
}

export default MathCaptcha; 