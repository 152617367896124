import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';

const HydrateFallback = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh',
        bgcolor: COLORS.background.default
      }}
    >
      <CircularProgress 
        size={60} 
        thickness={4} 
        sx={{ 
          color: COLORS.primary.main,
          mb: 3
        }} 
      />
      <Typography 
        variant="h6" 
        sx={{ 
          color: COLORS.text.secondary,
          fontWeight: 500
        }}
      >
        Loading application...
      </Typography>
    </Box>
  );
};

export default HydrateFallback; 