import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  TextField, 
  Button, 
  Snackbar, 
  Alert, 
  IconButton, 
  Drawer,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ContactsIcon from '@mui/icons-material/Contacts';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';
import NavMenu from './NavMenu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MobileContactDialog from './MobileContactDialog';
import ContactForm from '../shared/ContactForm';
import apiService from '../../services/apiService';
import InstallPrompt from '../common/InstallPrompt';

export const LayoutContext = React.createContext();

const EnhancedHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Box sx={{
      position: 'relative',
      background: 'white',
      borderBottom: '1px solid rgba(0,0,0,0.08)',
      py: { xs: 3, sm: 4 },
      backgroundColor: 'rgba(255, 255, 255, 0.98)',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`
      }
    }}>
      <Container maxWidth="lg">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          gap: 2
        }}>
          {/* Animated Logo */}
          <Box
            component="img"
            src="/logo.png"
            alt="Pebbles Logo"
            onClick={() => navigate('/')}
            sx={{
              height: { xs: '80px', sm: '110px' },
              width: 'auto',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
              }
            }}
          />

          {/* Animated Tagline */}
          <Box sx={{ 
            textAlign: 'center',
            position: 'relative',
            width: '100%',
            maxWidth: { xs: '280px', sm: '400px' },
            mx: 'auto',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: { xs: 0, sm: -40 },
              width: { xs: 20, sm: 30 },
              height: 2,
              background: COLORS.primary.main,
              transform: 'translateY(-50%)'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              right: { xs: 0, sm: -40 },
              width: { xs: 20, sm: 30 },
              height: 2,
              background: COLORS.primary.main,
              transform: 'translateY(-50%)'
            }
          }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1rem', sm: '1.2rem' },
                fontWeight: 500,
                color: COLORS.text.secondary,
                letterSpacing: '1px',
                position: 'relative',
                display: 'inline-block',
                px: 2,
                py: 1,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: `linear-gradient(135deg, ${COLORS.primary.main}08, ${COLORS.primary.light}05)`,
                  borderRadius: '4px',
                  transform: 'skew(-3deg)',
                  zIndex: -1
                }
              }}
            >
              Vi bygger teknologi med hjertet!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(12px, 16px) scale(1)',
    transition: 'all 200ms ease',
    color: COLORS.text.secondary,
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(12px, -8px) scale(0.75)',
      color: COLORS.primary.main,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: '1px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.primary.main,
      borderWidth: '1px',
    },
    // Explicitly disable hover styles
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      borderWidth: '1px',
    },
  }
}));

function Layout({ children }) {
  const navigate = useNavigate();

  const [siteSettings, setSiteSettings] = useState({
    site_title: '',
    meta_description: ''
  });

  const [siteInfo, setSiteInfo] = useState({
    email: '',
    phone: '',
    address: ''
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [submitting, setSubmitting] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showFloatingLogo, setShowFloatingLogo] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [mobileDialogOpen, setMobileDialogOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await axios.post('/api/submit-contact.php', formData);
      
      if (response.data.success) {
        setSnackbar({
          open: true,
          message: 'Takk for din henvendelse! Vi vil kontakte deg snart.',
          severity: 'success'
        });
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error(response.data.error || 'Failed to submit');
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Beklager, noe gikk galt. Vennligst prøv igjen senere.',
        severity: 'error'
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleContactDialogToggle = () => {
    setContactDialogOpen(!contactDialogOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if the user is offline
        if (!navigator.onLine) {
          console.log('User is offline, attempting to use cached data');
        }
        
        // Fetch site info using cached API service
        const infoResponse = await apiService.getSiteInfo();
        if (infoResponse.success) {
          setSiteInfo(infoResponse.data);
        } else {
          console.error('Error fetching site info:', infoResponse.error);
        }

        // Fetch site settings using cached API service
        const settingsResponse = await apiService.getSiteSettings();
        if (settingsResponse.success) {
          setSiteSettings(settingsResponse.data);
        } else {
          console.error('Error fetching site settings:', settingsResponse.error);
        }
      } catch (error) {
        console.error('Error fetching site data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercent = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setShowScrollTop(scrollPercent > 25);
      
      // Show floating logo when header is out of view (adjust 100 based on header height)
      setShowFloatingLogo(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleToggleDialog = (e) => {
      setMobileDialogOpen(e.detail.open);
    };

    window.addEventListener('toggleContactDialog', handleToggleDialog);
    return () => window.removeEventListener('toggleContactDialog', handleToggleDialog);
  }, []);

  useEffect(() => {
    const handleToggleForm = (event) => {
      setIsFormVisible(event.detail?.visible ?? !isFormVisible);
    };

    window.addEventListener('toggleForm', handleToggleForm);
    return () => window.removeEventListener('toggleForm', handleToggleForm);
  }, [isFormVisible]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const ContactInfo = ({ isDesktop = false }) => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2 
    }}>
      <Typography 
        variant="body2" 
        sx={{ 
          fontWeight: 500, 
          color: COLORS.text.primary, 
          fontSize: '0.9rem',
          textAlign: isDesktop ? 'right' : 'left'
        }}
      >
        Kontakt oss:
      </Typography>
      {siteInfo.email && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1.5,
          justifyContent: isDesktop ? 'flex-end' : 'flex-start',
          flexDirection: isDesktop ? 'row-reverse' : 'row'
        }}>
          <AlternateEmailIcon 
            sx={{ 
              color: COLORS.primary.main, 
              fontSize: 20
            }} 
          />
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.9rem',
              textAlign: isDesktop ? 'right' : 'left',
              width: '100%'
            }}
          >
            <a 
              href={`mailto:${siteInfo.email}`} 
              style={{ 
                color: COLORS.primary.main, 
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'color 0.2s ease-in-out'
              }}
            >
              {siteInfo.email}
            </a>
          </Typography>
        </Box>
      )}
      {siteInfo.phone && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1.5,
          justifyContent: isDesktop ? 'flex-end' : 'flex-start',
          flexDirection: isDesktop ? 'row-reverse' : 'row'
        }}>
          <LocalPhoneRoundedIcon 
            sx={{ 
              color: COLORS.text.secondary, 
              fontSize: 18
            }} 
          />
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.9rem',
              textAlign: isDesktop ? 'right' : 'left',
              width: '100%'
            }}
          >
            <a 
              href={`tel:${siteInfo.phone}`} 
              style={{ 
                color: COLORS.text.secondary, 
                textDecoration: 'none',
                transition: 'color 0.2s ease-in-out'
              }}
            >
              {siteInfo.phone}
            </a>
          </Typography>
        </Box>
      )}
      {siteInfo.address && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-start', 
          gap: 1.5,
          justifyContent: isDesktop ? 'flex-end' : 'flex-start',
          flexDirection: isDesktop ? 'row-reverse' : 'row'
        }}>
          <FmdGoodRoundedIcon 
            sx={{ 
              color: COLORS.text.secondary, 
              fontSize: 20,
              mt: 0.2
            }} 
          />
          <Typography 
            variant="body2" 
            sx={{ 
              color: COLORS.text.secondary, 
              fontSize: '0.9rem',
              lineHeight: 1.4,
              textAlign: isDesktop ? 'right' : 'left',
              width: '100%'
            }}
          >
            {siteInfo.address}
          </Typography>
        </Box>
      )}
    </Box>
  );

  const handleFormSuccess = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
    setIsFormVisible(false); // Close the form
  };

  return (
    <LayoutContext.Provider value={{ isFormVisible, setIsFormVisible, setMobileDialogOpen }}>
      <Helmet>
        <title>{siteSettings.site_title}</title>
        <meta name="description" content={siteSettings.meta_description} />
      </Helmet>
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh',
        bgcolor: COLORS.background.default
      }}>
        {/* Header */}
        <EnhancedHeader />

        {/* Mobile Menu Trigger */}
        {isMobile && (
          <IconButton
            onClick={() => setMobileMenuOpen(true)}
            sx={{
              position: 'fixed',
              top: 16,
              left: 16,
              zIndex: 1100,
              background: COLORS.background.paper,
              boxShadow: '0 2px 12px rgba(0,0,0,0.1)',
              '&:hover': {
                background: COLORS.background.paper,
              }
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Mobile Menu Drawer */}
        <Drawer
          anchor="left"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="mobile-nav-menu"
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
              maxWidth: '300px',
              background: COLORS.background.paper,
              boxShadow: '4px 0 24px rgba(0,0,0,0.05)',
            },
            zIndex: 1200
          }}
        >
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Meny</Typography>
            <IconButton onClick={() => setMobileMenuOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <NavMenu mobile onClose={() => setMobileMenuOpen(false)} />
        </Drawer>

        {/* Desktop Menu */}
        {!isMobile && (
          <Box sx={{ 
            position: 'fixed',
            left: 0,
            top: 0,
            height: '100%',
            zIndex: 1000,
            pointerEvents: 'none'  // This allows clicking through the container
          }}>
            <Box sx={{ pointerEvents: 'auto' }}>  {/* But menu itself is clickable */}
              <NavMenu />
            </Box>
          </Box>
        )}

        {/* Main Content Area */}
        <Box
          component="main"
          sx={{
            flex: 1,
            position: 'relative',
            bgcolor: COLORS.background.paper,
            width: '100%',
            overflowX: 'hidden'
          }}
        >
          {/* Content Area */}
          <Box sx={{ 
            width: '100%',
            position: 'relative',
            transition: 'margin-left 0.3s ease',
            maxWidth: '100vw'
          }}>
            {children}
          </Box>

          {/* Fixed Contact Form - Desktop */}
          {!isMobile && (
            <>
              {/* Toggle Button - when form is closed */}
              {!isFormVisible && (
                <Button
                  variant="contained"
                  onClick={() => setIsFormVisible(true)}
                  sx={{
                    position: 'fixed',
                    bottom: 40,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: `linear-gradient(45deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                    fontSize: '0.95rem',
                    padding: '8px 24px',
                    transition: 'transform 0.2s ease-in-out',
                    zIndex: 1200,
                    '&:hover': {
                      background: `linear-gradient(45deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      transform: 'translateX(-50%) translateY(-2px)'
                    }
                  }}
                  startIcon={<ContactsIcon />}
                >
                  Bli en del av oss
                </Button>
              )}

              {/* Contact Form */}
              {isFormVisible && (
                <Box 
                  sx={{ 
                    position: 'fixed',
                    bottom: 40,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100%',
                    maxWidth: '400px',
                    p: 2.5,
                    pt: 3.5,
                    borderRadius: 2,
                    backgroundColor: COLORS.background.paper,
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                    border: `1px solid ${COLORS.tertiary.main}`,
                    zIndex: 1000,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '4px',
                      background: `linear-gradient(to right, ${COLORS.primary.main}, ${COLORS.primary.light})`
                    }
                  }}
                >
                  <DialogTitle sx={{ 
                    m: 0, 
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: '1.1rem'
                  }}>
                    Interessert? Ta kontakt!
                    <IconButton 
                      onClick={() => setIsFormVisible(false)}
                      size="small"
                    >
                      <CloseIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </DialogTitle>
                  <Box sx={{ px: 2, pb: 2 }}>
                    <ContactForm 
                      onSuccess={handleFormSuccess}
                      onClose={() => setIsFormVisible(false)}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}

          {/* Contact Info - Desktop */}
          {!isMobile && (
            <Box sx={{ 
              position: 'fixed',
              bottom: 40,
              right: 40,
              textAlign: 'right',
              bgcolor: COLORS.background.paper,
              p: 2.5,
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
              zIndex: 1000,
              minWidth: '260px',
              '& .MuiSvgIcon-root': {
                transition: 'transform 0.2s ease-in-out'
              },
              '& a:hover': {
                color: COLORS.primary.main
              }
            }}>
              <ContactInfo isDesktop />
            </Box>
          )}

          {/* Contact FAB and Modal - Mobile */}
          {isMobile && (
            <>
              <Fab
                color="primary"
                size="small"
                aria-label="contact"
                onClick={() => setMobileDialogOpen(true)}
                sx={{
                  position: 'fixed',
                  bottom: { xs: 16, sm: 20 },
                  right: { xs: 16, sm: 20 },
                  background: `linear-gradient(45deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
                  width: '40px',
                  height: '40px',
                  minHeight: '40px',
                  '&:hover': {
                    background: `linear-gradient(45deg, ${COLORS.primary.dark}, ${COLORS.primary.main})`,
                    transform: 'translateY(-2px)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
                  },
                  transition: 'all 0.3s ease'
                }}
              >
                <ContactsIcon sx={{ fontSize: '20px' }} />
              </Fab>
            </>
          )}

          {/* Scroll to Top Button */}
          {showScrollTop && (
            <Box
              sx={{
                position: 'fixed',
                bottom: { xs: 16, sm: 35 },
                left: { xs: 16, sm: 35 },
                zIndex: 1000,
              }}
            >
              <Fab
                size="small"
                aria-label="scroll back to top"
                onClick={scrollToTop}
                sx={{
                  background: `linear-gradient(45deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                  color: '#fff',
                  '&:hover': {
                    background: `linear-gradient(45deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
                    transform: 'translateY(-2px)'
                  },
                  transition: 'transform 0.2s ease-in-out'
                }}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Box>
          )}

          {/* Floating Logo */}
          {!isMobile && (
            <Box
              component="img"
              src="/logo.png"
              alt="Pebbles Logo"
              onClick={() => {
                navigate('/');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              sx={{
                position: 'fixed',
                top: -7,
                left: showFloatingLogo ? '-7px' : '50%',
                transform: showFloatingLogo ? 
                  'translateX(0) scale(0.5)' :
                  'translateX(-50%) scale(0)',
                width: '120px',
                height: 'auto',
                cursor: 'pointer',
                zIndex: 1200,
                transition: 'all 0.5s ease',
                opacity: showFloatingLogo ? 0.9 : 0,
                visibility: showFloatingLogo ? 'visible' : 'hidden',
                '&:hover': {
                  transform: showFloatingLogo ? 
                    'translateX(0) scale(0.52) rotate(2deg)' :
                    'translateX(-50%) scale(0)',
                  opacity: 1
                }
              }}
            />
          )}
        </Box>

        {/* Copyright Footer */}
        <Box 
          component="footer" 
          sx={{
            mt: 'auto',
            py: 2.5,
            px: 2,
            textAlign: 'center',
            backgroundColor: COLORS.background.paper,
            borderTop: `1px solid ${COLORS.tertiary.main}`,
          }}
        >
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontWeight: 500,
              fontSize: '0.875rem'
            }}
          >
            © {new Date().getFullYear()} Pebbles. Alle rettigheter forbeholdt.
          </Typography>
        </Box>

        {/* Install Prompt for mobile users */}
        <InstallPrompt />
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            ...(snackbar.severity === 'success' && {
              bgcolor: '#E7F6E7',
              color: '#2E7D32',
              '& .MuiAlert-icon': {
                color: '#2E7D32'
              }
            })
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <MobileContactDialog 
        open={mobileDialogOpen}
        onClose={() => setMobileDialogOpen(false)}
        onSuccess={handleFormSuccess}
      />
    </LayoutContext.Provider>
  );
}

export default Layout;
