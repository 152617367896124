import React from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  Code as CodeIcon,
  BoltOutlined as BoltIcon,
  Palette as PaletteIcon,
  Security as SecurityIcon,
  Handshake as HandshakeIcon,
  LightbulbOutlined as LightbulbIcon,
  Rocket as RocketIcon
} from '@mui/icons-material';

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

// Wrap Paper component with motion
const AnimatedCard = motion.create(Paper);
const AnimatedBox = motion.create(Box);

const AreaCard = ({ iconType, title, description }) => {
  const IconComponent = {
    'code': CodeIcon,
    'bolt': BoltIcon,
    'palette': PaletteIcon,
    'security': SecurityIcon
  }[iconType] || CodeIcon;

  return (
    <AnimatedCard
      elevation={0}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, margin: "-100px" }}
      variants={fadeInUp}
      sx={{
        p: 4,
        height: '100%',
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-6px)',
          boxShadow: `0 12px 30px ${COLORS.primary.main}10`,
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1) rotate(5deg)',
            color: COLORS.primary.main
          }
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '4px',
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          opacity: 0,
          transition: 'opacity 0.3s ease',
        },
        '&:hover::before': {
          opacity: 1
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 48, 
          color: COLORS.primary.main,
          mb: 3,
          transition: 'all 0.3s ease',
        }} 
      />
      <Typography 
        variant="h5" 
        gutterBottom
        sx={{ 
          fontWeight: 600,
          color: COLORS.text.primary,
          mb: 2
        }}
      >
        {title}
      </Typography>
      <Typography 
        sx={{ 
          color: COLORS.text.secondary,
          mb: 3,
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
    </AnimatedCard>
  );
};

const BenefitItem = ({ icon, title, description }) => {
  const IconComponent = {
    'handshake': HandshakeIcon,
    'lightbulb': LightbulbIcon,
    'rocket': RocketIcon
  }[icon] || HandshakeIcon;

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
        p: 2,
        borderLeft: `3px solid ${COLORS.primary.main}15`,
        transition: 'all 0.3s ease',
        '&:hover': {
          borderLeft: `3px solid ${COLORS.primary.main}`,
          backgroundColor: `${COLORS.primary.main}05`,
          transform: 'translateX(4px)'
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 28,
          color: COLORS.primary.main,
          mt: 0.5
        }}
      />
      <Box>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 600,
            color: COLORS.text.primary,
            mb: 0.5
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body2"
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: { xs: 4, md: 6 }
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 1,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const VarEkspertiseSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    return {
      title: doc.querySelector('.hero-title .main-title')?.textContent || '',
      subtitle: doc.querySelector('.hero-title .subtitle')?.textContent || '',
      collaborationTitle: doc.querySelector('.collaboration-section h2')?.textContent || '',
      areas: Array.from(doc.querySelectorAll('.area-card')).map(card => ({
        iconType: {
          '💻': 'code',
          '⚡': 'bolt',
          '🎨': 'palette',
          '🔒': 'security'
        }[card.querySelector('.icon')?.textContent?.trim()] || 'code',
        title: card.querySelector('.area-title')?.textContent || '',
        description: card.querySelector('.area-content p')?.textContent || ''
      })),
      benefits: Array.from(doc.querySelectorAll('.benefits-list li')).map(item => ({
        icon: {
          '🤝': 'handshake',
          '💡': 'lightbulb',
          '🚀': 'rocket'
        }[item.querySelector('.icon')?.textContent?.trim()] || 'handshake',
        title: item.querySelector('.benefit-content h4')?.textContent || '',
        description: item.querySelector('.benefit-content p')?.textContent || ''
      }))
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content ekspertise"
      id="var-ekspertise"
      sx={{
        width: '100%',
        mb: { xs: 6, md: 8 }
      }}
    >
      {/* Section Title */}
      <SectionHeader title={menuItem?.title || 'VÅR EKSPERTISE'} />

      {/* Hero Section */}
      <Box component="header" className="hero-section" sx={{ 
        textAlign: 'center', 
        mb: 8,
        py: { xs: 6, md: 10 },
        background: `linear-gradient(135deg, ${COLORS.primary.main}08, ${COLORS.primary.light}08)`,
        borderRadius: { xs: 0, md: '40px' }
      }}>
        <Container maxWidth="md">
          <Typography 
            variant="h2" 
            className="main-title"
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              color: COLORS.text.primary,
              mb: 3,
              lineHeight: 1.2
            }}
          >
            {parsedContent.title}
          </Typography>
          <Typography 
            variant="h5"
            className="subtitle"
            sx={{ 
              color: COLORS.text.secondary,
              fontWeight: 400,
              lineHeight: 1.6,
              maxWidth: '800px',
              mx: 'auto',
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            {parsedContent.subtitle}
          </Typography>
        </Container>
      </Box>

      {/* Expertise Areas Grid */}
      <Box component="section" className="expertise-areas" sx={{ mb: 10 }}>
        <Grid container spacing={4}>
          {parsedContent.areas.map((area, index) => (
            <Grid item xs={12} md={6} key={index}>
              <AreaCard {...area} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Collaboration Section */}
      {/* <AnimatedBox
        component="section"
        className="collaboration-section"
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        variants={staggerChildren}
        sx={{ 
          maxWidth: 800,
          mx: 'auto',
          px: 3,
          py: 4,
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.03)'
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 4,
            textAlign: 'center',
            color: COLORS.text.primary,
            fontWeight: 600,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '40px',
              height: '3px',
              background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
              borderRadius: '2px'
            }
          }}
        >
          {parsedContent.collaborationTitle || ''}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {parsedContent.benefits.map((benefit, index) => (
            <BenefitItem key={index} {...benefit} />
          ))}
        </Box>
      </AnimatedBox> */}
    </Box>
  );
};

export default VarEkspertiseSection; 