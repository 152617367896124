// Define the future flags for React Router v7
export const routerFutureConfig = {
  v7_startTransition: true,
  v7_relativeSplatPath: true,
  v7_fetcherPersist: true,
  v7_normalizeFormMethod: true,
  v7_partialHydration: true,
  v7_skipActionErrorRevalidation: true
};

// Helper function to create router with future flags
export const createEnhancedRouter = (routes, createRouterFn) => {
  // Create the router with future flags
  return createRouterFn(routes, {
    future: routerFutureConfig,
    // Add basename if needed
    // basename: '/',
  });
}; 