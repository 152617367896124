import React from 'react';
import { Box, Typography, Button, Paper, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { COLORS } from '../../utils/colors';

const ContentErrorFallback = ({ error, resetErrorBoundary }) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleContactSupport = () => {
    window.location.href = 'mailto:hei@pebbles.no?subject=Website%20Error&body=I%20encountered%20an%20error%20on%20the%20website:%20' + encodeURIComponent(error || 'Content failed to load');
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          textAlign: 'center',
          border: `1px solid ${COLORS.primary.main}15`,
          boxShadow: '0 4px 20px rgba(0,0,0,0.05)'
        }}
      >
        <ErrorOutlineIcon 
          sx={{ 
            fontSize: 60, 
            color: COLORS.primary.main,
            mb: 2
          }} 
        />
        
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          Oops! Something went wrong
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3, color: COLORS.text.secondary }}>
          {error || 'We couldn\'t load the content you requested. This could be due to a temporary issue or network problem.'}
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            sx={{
              px: 3,
              py: 1,
              borderRadius: 2,
              fontWeight: 500
            }}
          >
            Refresh Page
          </Button>
          
          <Button
            variant="outlined"
            color="primary"
            onClick={handleContactSupport}
            sx={{
              px: 3,
              py: 1,
              borderRadius: 2,
              fontWeight: 500
            }}
          >
            Contact Support
          </Button>
        </Box>
        
        <Typography variant="body2" sx={{ mt: 4, color: COLORS.text.secondary, fontSize: '0.85rem' }}>
          If the problem persists, please try again later or contact our support team.
        </Typography>
      </Paper>
    </Container>
  );
};

export default ContentErrorFallback; 