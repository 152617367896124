// Utility to suppress specific React Router warnings in development
export const suppressReactRouterWarnings = () => {
  // Only run in development mode
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  try {
    // Store the original console.warn
    const originalWarn = console.warn;

    // Override console.warn to filter out specific React Router warnings
    console.warn = (...args) => {
      // Check if this is a React Router warning we want to suppress
      const warningText = args[0]?.toString() || '';
      
      const reactRouterWarnings = [
        'React Router Future Flag Warning',
        'v7_startTransition',
        'v7_relativeSplatPath',
        'v7_fetcherPersist',
        'v7_normalizeFormMethod',
        'v7_partialHydration',
        'v7_skipActionErrorRevalidation',
        '<RouterProvider fallbackElement>'
      ];
      
      // If it's a React Router warning we're already handling, don't log it
      if (reactRouterWarnings.some(warning => warningText.includes(warning))) {
        // Uncomment this to see which warnings are being suppressed
        // originalWarn('Suppressed warning:', ...args);
        return;
      }
      
      // Otherwise, pass through to the original console.warn
      originalWarn(...args);
    };
  } catch (error) {
    // If something goes wrong, just log it and continue
    console.error('Error setting up warning suppression:', error);
  }
}; 