import React, { useContext } from 'react';
import { Box, Typography, Button, Container, useMediaQuery, useTheme } from '@mui/material';
import { COLORS } from '../../utils/colors';
import ContactsIcon from '@mui/icons-material/Contacts';
import { LayoutContext } from '../public/Layout';

const CtaSection = () => {
  const { setIsFormVisible, setMobileDialogOpen } = useContext(LayoutContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleContactClick = (event) => {
    event.preventDefault();
    if (isMobile) {
      setMobileDialogOpen(true);
    } else {
      setIsFormVisible(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, md: 6 } }}>
      <Box 
        className="cta-section" 
        sx={{ 
          bgcolor: COLORS.primary.main,
          borderRadius: 4,
          p: { xs: 4, md: 6 },
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(135deg, ${COLORS.primary.main}50, transparent)`,
            zIndex: 1
          }
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          <Typography 
            variant="h2" 
            className="cta-title" 
            sx={{ 
              color: 'white',
              fontWeight: 700,
              mb: 2,
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
            }}
          >
            Interessert?
          </Typography>
          <Typography 
            className="cta-description" 
            sx={{ 
              color: 'white',
              opacity: 0.9,
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.6
            }}
          >
            Er du en dyktig IT-konsulent som ønsker frihet og fleksibilitet, kombinert med fordelene av et sterkt faglig fellesskap? Bli med i vårt unike konsulentnettverk i dag!
          </Typography>
          <Button 
            variant="contained"
            onClick={handleContactClick}
            startIcon={<ContactsIcon />}
            sx={{ 
              bgcolor: 'white',
              color: COLORS.primary.main,
              px: { xs: 3, md: 4 },
              py: { xs: 1.2, md: 1.5 },
              fontSize: { xs: '0.9rem', md: '1rem' },
              fontWeight: 500,
              borderRadius: '30px',
              boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: 'white',
                transform: 'translateY(-2px)',
                boxShadow: '0 12px 24px rgba(0,0,0,0.2)'
              }
            }}
          >
            Ta kontakt med oss
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CtaSection; 