import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
} from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';
import axios from '../../utils/axios';

function MenuManager() {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get('/api/menu-items.php', {
        params: { 
          _t: new Date().getTime(),
          action: 'get'
        }
      });
      if (response.data.success) {
        const sortedItems = response.data.data.sort((a, b) => a.order_index - b.order_index);
        setMenuItems(sortedItems);
      }
    } catch (error) {
      console.error('Fetch error:', error.response?.data || error.message);
      setError('Error fetching menu items');
    } finally {
      setLoading(false);
    }
  };

  const handleMoveItem = async (id, direction) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/menu-items.php', {
        action: 'reorder',
        id,
        direction
      });
      
      if (response.data.success) {
        await fetchMenuItems();
        showSnackbar('Menu order updated successfully');
      } else {
        throw new Error(response.data.error || 'Error updating menu order');
      }
    } catch (error) {
      console.error('Move item error:', error.response?.data || error.message);
      showSnackbar(
        error.message || 'Server error while updating menu order',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (item = null) => {
    if (item) {
      setFormData({
        title: item.title,
        content: item.content || '',
      });
      setEditingItem(item);
    } else {
      setFormData({
        title: '',
        content: '',
      });
      setEditingItem(null);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingItem(null);
    setFormData({ title: '', content: '' });
  };

  const handleSubmit = async () => {
    try {
      // Clean up the content before saving
      const cleanContent = formData.content
        .replace(/\n\s*\n/g, '\n')
        .replace(/&nbsp;/g, ' ')
        .replace(/<br\s*\/?>\n*/g, '')
        .replace(/\n/g, '')
        .replace(/>\s+</g, '><')
        .replace(/\s+/g, ' ')
        .trim();

      const dataToSubmit = {
        ...formData,
        content: cleanContent
      };

      if (editingItem) {
        // Update existing item
        await axios.post('/api/menu-items.php', {
          id: editingItem.id,
          ...dataToSubmit
        });
        showSnackbar('Menu item updated successfully');
      } else {
        // Create new item
        await axios.post('/api/menu-items.php', dataToSubmit);
        showSnackbar('Menu item created successfully');
      }
      handleCloseDialog();
      fetchMenuItems();
    } catch (error) {
      showSnackbar('Error saving menu item', 'error');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this menu item?')) {
        try {
            console.log('Deleting menu item:', id);
            const response = await axios.delete('/api/menu-items.php', { 
                data: { id: id }
            });
            
            if (response.data.success) {
                showSnackbar('Menu item deleted successfully');
                await fetchMenuItems(); // Refresh the list
            } else {
                throw new Error(response.data.error || 'Failed to delete');
            }
        } catch (error) {
            console.error('Delete error:', error);
            showSnackbar(
                error.response?.data?.error || 'Error deleting menu item', 
                'error'
            );
        }
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleSave = async (data) => {
    await axios.post('/api/menu-items.php', data);
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Menu Manager</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Menu Item
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      ) : (
        <Paper elevation={2}>
          <List>
            {menuItems?.map((item, index) => (
              <React.Fragment key={item.id}>
                {index > 0 && <Divider />}
                <ListItem
                  secondaryAction={
                    <Box>
                      <IconButton
                        edge="end"
                        aria-label="move up"
                        onClick={() => handleMoveItem(item.id, 'up')}
                        disabled={index === 0}
                      >
                        <UpIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="move down"
                        onClick={() => handleMoveItem(item.id, 'down')}
                        disabled={index === menuItems.length - 1}
                      >
                        <DownIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleOpenDialog(item)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemText
                    primary={item.title}
                    secondary={`/${item.slug}`}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingItem ? 'Edit Menu Item' : 'Add Menu Item'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              fullWidth
            />
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Content
              </Typography>
              <RichTextEditor
                initialHtml={formData.content}
                onChange={(content) => setFormData({ ...formData, content })}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingItem ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MenuManager;
