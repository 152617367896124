import React from 'react';
import { Box, Typography, Grid, Paper, Button, Container, useTheme, useMediaQuery } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  AccountCircle as PersonIcon,
  Handshake as HandshakeIcon,
  Rocket as RocketIcon,
  SupportAgent as AdminIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.99] }
  }
};

const staggerChildren = {
  animate: {
    transition: { staggerChildren: 0.2 }
  }
};

// Styled components
const AnimatedBox = motion.create(Box);
const AnimatedPaper = motion.create(Paper);

const StatItem = ({ number, label }) => (
  <Box
    sx={{
      textAlign: 'center',
      px: { xs: 1.5, sm: 2 },
      position: 'relative',
      '&:not(:last-child)::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: '20%',
        height: '60%',
        width: '1px',
        bgcolor: 'rgba(255,255,255,0.2)'
      }
    }}
  >
    <Typography
      variant="h3"
      sx={{
        fontWeight: 700,
        color: 'white',
        mb: { xs: 0.5, sm: 1 },
        fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }
      }}
    >
      {number}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        color: 'rgba(255,255,255,0.8)',
        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
      }}
    >
      {label}
    </Typography>
  </Box>
);

const BenefitCard = ({ icon, title, description, features }) => {
  const IconComponent = {
    '🎯': PersonIcon,
    '🤝': HandshakeIcon,
    '🚀': RocketIcon,
    '🆘': AdminIcon
  }[icon] || PersonIcon;

  return (
    <AnimatedBox
      component="article"
      className="benefit-card"
      variants={fadeInUp}
      sx={{
        p: 2.5,
        borderRadius: 3,
        bgcolor: 'white',
        boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
        transition: 'all 0.3s ease',
        border: `1px solid ${COLORS.primary.main}06`,
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 20px rgba(0,0,0,0.06)',
          borderColor: `${COLORS.primary.main}15`,
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          },
          '&::before': {
            opacity: 1
          }
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '3px',
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          opacity: 0,
          transition: 'opacity 0.3s ease'
        }
      }}
    >
      <Box className="card-content">
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'flex-start',
            mb: 1.5
          }}
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: `linear-gradient(135deg, ${COLORS.primary.light}15, ${COLORS.primary.main}08)`,
              flexShrink: 0,
              mr: 1.5
            }}
          >
            <IconComponent 
              sx={{ 
                fontSize: 20,
                color: COLORS.primary.main,
                transition: 'all 0.3s ease'
              }} 
            />
          </Box>
          <Typography 
            variant="h5"
            sx={{ 
              fontWeight: 600,
              fontSize: { xs: '1.25rem', md: '1.45rem' },
              lineHeight: 1.3,
              color: COLORS.text.primary
            }}
          >
            {title}
          </Typography>
        </Box>

        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6,
            fontSize: { xs: '0.95rem', md: '1rem' },
            mb: 1.5
          }}
        >
          {description}
        </Typography>

        {features && (
          <Box component="ul" 
            sx={{ 
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {features.map((feature, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  mb: 1,
                  '&:last-child': { mb: 0 },
                  color: COLORS.text.secondary,
                  fontSize: { xs: '0.9rem', md: '0.95rem' },
                  lineHeight: 1.5
                }}
              >
                <ChevronRightIcon 
                  sx={{ 
                    fontSize: 16,
                    color: COLORS.primary.main,
                    mr: 0.75,
                    mt: '4px'
                  }} 
                />
                {feature}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </AnimatedBox>
  );
};

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: 2
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 0.5,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const KonseptSection = ({ content, menuItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleContactClick = () => {
    if (isMobile) {
      window.dispatchEvent(new CustomEvent('toggleContactDialog', { 
        detail: { open: true }
      }));
    } else {
      window.dispatchEvent(new CustomEvent('toggleForm', { 
        detail: { visible: true }
      }));
    }
  };

  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    return {
      overline: doc.querySelector('.overline')?.textContent || '',
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      stats: Array.from(doc.querySelectorAll('.stat-item')).map(stat => ({
        number: stat.querySelector('.stat-number')?.textContent || '',
        label: stat.querySelector('.stat-label')?.textContent || ''
      })),
      benefits: {
        title: doc.querySelector('.benefits-header .section-title')?.textContent || '',
        description: doc.querySelector('.benefits-header .section-description')?.textContent || '',
        items: Array.from(doc.querySelectorAll('.benefit-card')).map(card => ({
          icon: card.querySelector('.icon')?.textContent || '',
          title: card.querySelector('.benefit-title')?.textContent || '',
          description: card.querySelector('.benefit-description')?.textContent || '',
          features: Array.from(card.querySelectorAll('.benefit-features li')).map(li => li.textContent || ''),
          featured: card.classList.contains('featured')
        }))
      },
      cta: {
        title: doc.querySelector('.cta-title')?.textContent || '',
        description: doc.querySelector('.cta-description')?.textContent || '',
        primaryButton: {
          text: doc.querySelector('.primary-button')?.textContent || '',
          url: doc.querySelector('.primary-button')?.getAttribute('href') || ''
        },
        secondaryButton: {
          text: doc.querySelector('.secondary-button')?.textContent || '',
          url: doc.querySelector('.secondary-button')?.getAttribute('href') || ''
        }
      }
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content konseptet"
      id="konseptet"
    >
      {/* Full-width background wrapper */}
      <Box
        sx={{
          width: '100vw',
          marginLeft: 'calc(-50vw + 50%)',
          marginRight: 'calc(-50vw + 50%)',
          background: `linear-gradient(135deg, #C82551 0%, #A71C43 100%)`,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            background: 'radial-gradient(circle at 70% 50%, rgba(255,255,255,0.1) 0%, transparent 70%)',
            opacity: 0.6
          },
          '& .floating-icon': {
            position: 'absolute',
            width: '40px',
            height: '40px',
            backgroundSize: 'contain',
            opacity: 0.05,
            animation: 'floatIcon 20s linear infinite',
            '&.heart': {
              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cpath d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'/%3E%3C/svg%3E") no-repeat center center`
            },
            '&.code': {
              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cpath d='M8.293 6.293L2.586 12l5.707 5.707 1.414-1.414L5.414 12l4.293-4.293zm7.414 11.414L21.414 12l-5.707-5.707-1.414 1.414L18.586 12l-4.293 4.293z'/%3E%3C/svg%3E") no-repeat center center`
            },
            '&.gear': {
              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cpath d='M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z'/%3E%3C/svg%3E") no-repeat center center`
            },
            '&:nth-of-type(2)': {
              left: '25%',
              animationDelay: '-5s',
              animationDuration: '23s'
            },
            '&:nth-of-type(3)': {
              left: '50%',
              animationDelay: '-10s',
              animationDuration: '25s'
            },
            '&:nth-of-type(4)': {
              left: '75%',
              animationDelay: '-7s',
              animationDuration: '18s'
            },
            '&:nth-of-type(5)': {
              left: '35%',
              animationDelay: '-15s',
              animationDuration: '21s'
            },
            '&:nth-of-type(6)': {
              left: '65%',
              animationDelay: '-3s',
              animationDuration: '19s'
            }
          },
          '@keyframes floatIcon': {
            '0%': {
              transform: 'translateY(100vh) scale(1) rotate(0deg)',
              opacity: 0
            },
            '50%': {
              opacity: 0.05
            },
            '100%': {
              transform: 'translateY(-100px) scale(0.8) rotate(360deg)',
              opacity: 0
            }
          }
        }}
      >
        {/* Add floating heart elements */}
        <Box className="floating-icon heart" sx={{ left: '10%' }} />
        <Box className="floating-icon code" sx={{ left: '30%' }} />
        <Box className="floating-icon gear" sx={{ left: '50%' }} />
        <Box className="floating-icon heart" sx={{ left: '70%' }} />
        <Box className="floating-icon code" sx={{ left: '20%' }} />
        <Box className="floating-icon gear" sx={{ left: '90%' }} />

        {/* Hero Section */}
        <Box
          sx={{
            height: { xs: '50vh', md: '65vh' },
            minHeight: '400px',
            overflow: 'hidden',
            maxWidth: '1200px',
            margin: '0 auto',
            position: 'relative'
          }}
        >
          <Container maxWidth="lg" sx={{ 
            height: '100%', 
            position: 'relative', 
            zIndex: 1,
            py: { xs: 2, md: 3 }
          }}>
            <Grid container sx={{ height: '100%' }}>
              {/* Left Content */}
              <Grid item xs={12} md={7}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: { xs: 'center', md: 'left' }
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      color: 'rgba(255,255,255,0.9)',
                      letterSpacing: '4px',
                      mb: 1,
                      display: 'block'
                    }}
                  >
                    {parsedContent.overline}
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '2rem', md: '3rem' },
                      fontWeight: 700,
                      mb: 1,
                      lineHeight: 1.2
                    }}
                  >
                    {parsedContent.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: { xs: '1.1rem', md: '1.3rem' },
                      fontWeight: 400,
                      mb: 4,
                      opacity: 0.9
                    }}
                  >
                    {parsedContent.subtitle}
                  </Typography>

                  {/* Stats with updated alignment */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      gap: { xs: 3, md: 6 }
                    }}
                  >
                    {parsedContent.stats.map((stat, index) => (
                      <StatItem key={index} {...stat} />
                    ))}
                  </Box>
                </motion.div>
              </Grid>

              {/* Right Image */}
              <Grid item xs={12} md={5}
                sx={{
                  height: '100%',
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                }}
              >
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  style={{
                    width: '90%',
                    height: '60%',
                    position: 'relative',
                    perspective: '1000px'
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      transform: 'rotateY(-12deg) rotateX(4deg)',
                      transformStyle: 'preserve-3d',
                      transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                      cursor: 'pointer',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: '-20px',
                        background: '#FCFBFC',
                        backdropFilter: 'blur(15px)',
                        borderRadius: '20px',
                        boxShadow: 'none',
                        transform: 'translateZ(-50px)',
                        transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        inset: '-20px',
                        borderRadius: '20px',
                        opacity: 0,
                        transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                      },
                      '&:hover': {
                        transform: 'rotateY(-8deg) rotateX(2deg) translateZ(30px) scale(1.05)',
                        '&::before': {
                          transform: 'translateZ(-70px)',
                        },
                        '&::after': {
                          opacity: 1,
                          transform: 'translateZ(-60px)',
                        },
                        '& img': {
                          transform: 'scale(1.05) translateZ(20px)',
                          filter: 'brightness(1.05)',
                        }
                      }
                    }}
                  >
                    <img 
                      src="/images/techwithheart.webp"
                      alt="Pebbles Technology with Heart"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '12px',
                        position: 'relative',
                        zIndex: 1,
                        filter: 'none',
                        transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                        transform: 'translateZ(0)'
                      }}
                    />
                  </Box>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      {/* Benefits Section */}
      <Container maxWidth="lg" sx={{ 
        pt: { xs: 8, md: 12 },
        pb: { xs: 4, md: 6 }
      }}>
        <SectionHeader title={menuItem?.title || 'KONSEPTET'} />
        
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 2, 
              fontWeight: 700,
              fontSize: { xs: '2rem', sm: '2.4rem', md: '2.75rem' }
            }}
          >
            {parsedContent.benefits.title}
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              color: COLORS.text.secondary,
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.25rem' },
              lineHeight: 1.6,
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            {parsedContent.benefits.description}
          </Typography>
        </Box>

        <Grid container spacing={2.5}>
          {parsedContent.benefits.items.map((benefit, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <BenefitCard {...benefit} />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* CTA Section */}
      <Container maxWidth="md" sx={{ 
        pt: 2,
        pb: { xs: 4, md: 6 }
      }}>
        <Box
          sx={{
            textAlign: 'center',
            bgcolor: COLORS.primary.main,
            borderRadius: 4,
            p: { xs: 4, md: 6 },
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
            minHeight: { xs: '240px', md: '280px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(135deg, ${COLORS.primary.main}50, transparent)`,
              zIndex: 1
            }
          }}
        >
          <Box sx={{ position: 'relative', zIndex: 2 }}>
            <Typography 
              variant="h3" 
              sx={{ 
                mb: 2, 
                fontWeight: 700,
                fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.125rem' }  // Smaller on mobile
              }}
            >
              {parsedContent.cta.title}
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
              {parsedContent.cta.description}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 2, sm: 3 },
              justifyContent: 'center',
              width: { xs: '100%', sm: 'auto' }
            }}>
              <Button
                variant="contained"
                onClick={handleContactClick}
                sx={{
                  bgcolor: 'white',
                  color: COLORS.primary.main,
                  px: { xs: 2.5, md: 3.5 },
                  py: { xs: 1, md: 1.2 },
                  fontSize: { xs: '0.875rem', md: '1rem' },
                  fontWeight: 500,
                  borderRadius: '30px',
                  width: { xs: '100%', sm: 'auto' },
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                  transition: 'all 0.3s ease',
                  transformOrigin: 'center center',
                  willChange: 'transform',
                  '&:hover': {
                    bgcolor: 'white',
                    transform: 'scale(1.05)',
                    boxShadow: '0 12px 24px rgba(0,0,0,0.2)'
                  },
                  '&:active': {
                    transform: 'scale(1)',
                  }
                }}
              >
                {parsedContent.cta.primaryButton.text}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default KonseptSection; 