import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import 'intersection-observer';
import './utils/axiosConfig'; // Import Axios configuration
import { suppressReactRouterWarnings } from './utils/errorHandling';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Suppress React Router warnings in development
suppressReactRouterWarnings();

// Add iOS Safari detection
const isIOSSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) 
                    && /iPad|iPhone|iPod/.test(navigator.userAgent);

// Add touchstart event listener for iOS Safari
if (isIOSSafari) {
  document.addEventListener('touchstart', {}, true);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
