/**
 * Decodes HTML entities in a string
 * @param {string} html - The HTML string to decode
 * @returns {string} The decoded string
 */
export const decodeHtmlEntities = (html) => {
  if (!html) return '';
  
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Formats HTML with proper indentation
 * @param {string} html - The HTML string to format
 * @returns {string} The formatted HTML string
 */
export const formatHtml = (html) => {
  if (!html) return '';

  return html
    .replace(/></g, '>\n<')                    // Add newline between tags
    .replace(/(<\/[^>]+>)/g, '$1\n')          // Add newline after closing tags
    .replace(/(<[^\/][^>]*[^\/]>)/g, '\n$1')  // Add newline before opening tags
    .split('\n')                              // Split into lines
    .filter(line => line.trim())              // Remove empty lines
    .map(line => '  '.repeat(getIndentLevel(line)) + line.trim()) // Add proper indentation
    .join('\n');                              // Join back with newlines
};

/**
 * Calculates the indent level for a line of HTML
 * @param {string} line - The line of HTML
 * @returns {number} The indent level
 */
const getIndentLevel = (line) => {
  let level = 0;
  const matches = line.match(/<\/?[^>]+>/g) || [];
  
  for (const match of matches) {
    if (match.startsWith('</')) level--;
    else if (!match.endsWith('/>')) level++;
  }
  return Math.max(0, level);
};

/**
 * Cleans HTML content by removing excessive whitespace and formatting
 * @param {string} html - The HTML string to clean
 * @returns {string} The cleaned HTML string
 */
export const cleanHtml = (html) => {
  if (!html) return '';
  
  return html
    .replace(/\n\s*\n/g, '\n')               // Remove multiple empty lines
    .replace(/&nbsp;/g, ' ')                 // Replace &nbsp; with spaces
    .replace(/<br\s*\/?>\n*/g, '')          // Remove <br> tags
    .replace(/\n/g, '')                      // Remove newlines
    .replace(/>\s+</g, '><')                // Remove whitespace between tags
    .replace(/\s+/g, ' ')                   // Replace multiple spaces with single space
    .trim();                                // Remove leading/trailing whitespace
};