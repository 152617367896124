import React, { useState } from 'react';
import { Box, Paper, IconButton, Tooltip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Code, Visibility } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../utils/colors';

const StyledTextarea = styled('textarea')(({ theme }) => ({
  width: '100%',
  minHeight: '400px',
  padding: '16px',
  fontFamily: 'monospace',
  fontSize: '14px',
  lineHeight: 1.5,
  border: 'none',
  resize: 'vertical',
  backgroundColor: '#f8f9fa',
  color: '#333',
  '&:focus': {
    outline: 'none',
    backgroundColor: '#fff',
    boxShadow: 'inset 0 0 0 2px ' + COLORS.primary.main
  }
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '400px',
  padding: '16px',
  backgroundColor: '#fff',
  border: `1px solid ${COLORS.tertiary.main}`,
  borderRadius: '4px',
  '& h1, & h2, & h3': {
    marginBottom: '1rem',
    color: COLORS.text.primary
  },
  '& p': {
    marginBottom: '1rem',
    color: COLORS.text.secondary
  },
  '& .icon': {
    fontSize: '2rem',
    marginBottom: '0.5rem'
  }
}));

const RichTextEditor = ({ initialHtml = '', onChange }) => {
  const [viewMode, setViewMode] = useState('code');
  const [content, setContent] = useState(initialHtml);

  const handleChange = (e) => {
    const newContent = e.target.value;
    // Remove excessive whitespace and normalize line breaks
    const cleanContent = newContent
      .replace(/\n\s*\n/g, '\n') // Remove multiple empty lines
      .replace(/&nbsp;/g, ' ')   // Replace &nbsp; with regular spaces
      .replace(/<br\s*\/?>\n/g, '\n') // Remove <br> followed by newline
      .trim();
    
    setContent(cleanContent);
    onChange(cleanContent);
  };

  const formatHtml = (html) => {
    // First, clean the HTML
    const cleanHtml = html
      .replace(/&nbsp;/g, ' ')
      .replace(/<br\s*\/?>\n*/g, '')
      .replace(/\n/g, '')
      .replace(/>\s+</g, '><')
      .trim();

    // Then format it nicely
    const formatted = cleanHtml
      .replace(/></g, '>\n<')                    // Add newline between tags
      .replace(/(<\/[^>]+>)/g, '$1\n')          // Add newline after closing tags
      .replace(/(<[^\/][^>]*[^\/]>)/g, '\n$1')  // Add newline before opening tags
      .split('\n')                              // Split into lines
      .filter(line => line.trim())              // Remove empty lines
      .map(line => '  '.repeat(getIndentLevel(line)) + line.trim()) // Add proper indentation
      .join('\n');                              // Join back with newlines

    return formatted;
  };

  // Helper function to calculate indent level
  const getIndentLevel = (line) => {
    let level = 0;
    const matches = line.match(/<\/?[^>]+>/g) || [];
    
    for (const match of matches) {
      if (match.startsWith('</')) level--;
      else if (!match.endsWith('/>')) level++;
    }
    return Math.max(0, level);
  };

  return (
    <Box>
      <Paper 
        variant="outlined" 
        sx={{ 
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1
        }}
      >
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(e, newMode) => newMode && setViewMode(newMode)}
          size="small"
        >
          <ToggleButton value="code">
            <Tooltip title="Code View">
              <Code fontSize="small" />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="preview">
            <Tooltip title="Preview">
              <Visibility fontSize="small" />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>

      {viewMode === 'code' ? (
        <StyledTextarea
          value={formatHtml(content)}
          onChange={handleChange}
          spellCheck="false"
        />
      ) : (
        <PreviewContainer 
          dangerouslySetInnerHTML={{ __html: content }} 
        />
      )}
    </Box>
  );
};

export default RichTextEditor;
