import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
  Divider,
} from '@mui/material';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';
import MathCaptcha from './MathCaptcha';

const MAX_FAILED_ATTEMPTS = 5;
const BLOCK_DURATION_HOURS = 3;

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockEndTime, setBlockEndTime] = useState(null);

  useEffect(() => {
    // Check if there's an existing block
    const storedBlockEndTime = localStorage.getItem('loginBlockEndTime');
    if (storedBlockEndTime) {
      const endTime = new Date(storedBlockEndTime);
      if (endTime > new Date()) {
        setIsBlocked(true);
        setBlockEndTime(endTime);
      } else {
        // Block has expired
        localStorage.removeItem('loginBlockEndTime');
        localStorage.removeItem('failedAttempts');
      }
    }

    // Load failed attempts
    const storedFailedAttempts = localStorage.getItem('failedAttempts');
    if (storedFailedAttempts) {
      setFailedAttempts(parseInt(storedFailedAttempts));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
        console.log('Attempting login...');
        const response = await axios.post('/api/login.php', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if (response.data.success) {
            console.log('Login successful, token:', response.data.token);
            // Save token first
            localStorage.setItem('token', response.data.token);
            // Verify token was saved
            const savedToken = localStorage.getItem('token');
            console.log('Saved token verification:', savedToken);
            
            // Only navigate if token was saved
            if (savedToken) {
                console.log('Token saved successfully, navigating to dashboard...');
                navigate('/kontrollpanel/dashboard');
            } else {
                console.error('Token not saved properly');
                setError('Login failed - token storage error');
            }
        }
    } catch (error) {
        console.error('Login error details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
            headers: error.response?.headers
        });
        setError(error.response?.data?.error || 'Login failed');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCaptchaValidation = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.background.default
      }}
    >
      <Paper
        elevation={3}
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          backgroundColor: COLORS.background.paper,
          borderRadius: 2,
          border: `1px solid ${COLORS.tertiary.main}`
        }}
      >
        <Typography 
          variant="h5" 
          component="h1" 
          align="center" 
          sx={{ 
            color: COLORS.text.primary,
            fontWeight: 500,
            mb: 1
          }}
        >
          Pebbles Admin
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TextField
          required
          fullWidth
          label="Brukernavn"
          name="username"
          value={formData.username}
          onChange={handleChange}
          autoComplete="username"
          disabled={isBlocked}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: COLORS.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: COLORS.primary.main,
              }
            }
          }}
        />

        <TextField
          required
          fullWidth
          type="password"
          label="Passord"
          name="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="current-password"
          disabled={isBlocked}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: COLORS.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: COLORS.primary.main,
              }
            }
          }}
        />

        <Divider sx={{ my: 1 }} />
        
        <MathCaptcha onValidate={handleCaptchaValidation} />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          disabled={isBlocked || !isCaptchaValid}
          sx={{ 
            mt: 2,
            bgcolor: COLORS.primary.main,
            '&:hover': {
              bgcolor: COLORS.primary.dark
            }
          }}
        >
          Logg inn
        </Button>
      </Paper>
    </Box>
  );
}

export default Login;
