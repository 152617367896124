import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { COLORS } from '../../utils/colors';
import axios from '../../utils/axios';

const ContactForm = ({ onSuccess, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    try {
      const response = await axios.post('/api/submit-contact.php', formData);
      
      if (response.data.success) {
        // Clear form
        setFormData({
          name: '',
          email: '',
          message: ''
        });
        
        // Show success message
        onSuccess?.(response.data.message || 'Takk for din henvendelse!', 'success');
        onClose?.();
      } else {
        throw new Error(response.data.message || 'Noe gikk galt');
      }
    } catch (error) {
      console.error('Contact form error:', error);
      onSuccess?.(
        error.response?.data?.message || 'Det oppstod en feil. Vennligst prøv igjen senere.',
        'error'
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Navn"
          name="name"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          margin="normal"
          required
          size="small"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="E-post"
          name="email"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
          margin="normal"
          required
          size="small"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Melding"
          name="message"
          multiline
          rows={3}
          value={formData.message}
          onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
          margin="normal"
          required
          size="small"
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={submitting}
          sx={{
            bgcolor: COLORS.primary.main,
            color: 'white',
            py: 1,
            '&:hover': {
              bgcolor: COLORS.primary.dark
            }
          }}
        >
          {submitting ? 'Sender...' : 'Send melding'}
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm; 