import React from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  Balance as BalanceIcon,
  MonetizationOn as MoneyIcon,
  Handshake as HandshakeIcon,
  TrendingUp as TrendingIcon,
  Business as BusinessIcon,
  Settings as SettingsIcon,
  Security as SecurityIcon,
  Work as WorkIcon,
  Groups as GroupsIcon,
  School as SchoolIcon
} from '@mui/icons-material';

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

const AnimatedCard = motion.create(Paper);
const AnimatedBox = motion.create(Box);

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: 2
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 0.5,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const AspectCard = ({ iconType, title, description, variant = 'primary' }) => {
  const IconComponent = {
    'balance': BalanceIcon,
    'money': MoneyIcon,
    'handshake': HandshakeIcon,
    'trending': TrendingIcon,
    'business': BusinessIcon,
    'settings': SettingsIcon
  }[iconType] || BalanceIcon;

  return (
    <AnimatedCard
      elevation={0}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      variants={fadeInUp}
      sx={{
        p: 4,
        height: '100%',
        minHeight: '240px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: variant === 'primary' ? '24px' : '16px',
        background: variant === 'primary' 
          ? `linear-gradient(135deg, ${COLORS.primary.main}08, ${COLORS.primary.light}15)`
          : 'white',
        boxShadow: variant === 'primary'
          ? 'none'
          : '0 1px 5px rgba(0,0,0,0.06)',
        border: variant === 'primary' 
          ? 'none'
          : '1px solid #FFF0F4',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-6px)',
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 48, 
          color: COLORS.primary.main,
          mb: 3,
          transition: 'all 0.3s ease',
        }} 
      />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography 
          variant="h5" 
          className="aspect-title"
          sx={{ 
            fontWeight: 600,
            color: COLORS.text.primary,
            mb: 2
          }}
        >
          {title}
        </Typography>
        <Typography 
          className="aspect-content"
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6,
            flex: 1
          }}
        >
          {description}
        </Typography>
      </Box>
    </AnimatedCard>
  );
};

const BenefitItem = ({ icon, title, description }) => {
  const IconComponent = {
    'security': SecurityIcon,
    'work': WorkIcon,
    'groups': GroupsIcon,
    'school': SchoolIcon
  }[icon] || SecurityIcon;

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        gap: 3,
        mb: 4,
        '&:last-child': { mb: 0 }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 40,
          color: COLORS.primary.main,
          mt: 1,
          flexShrink: 0
        }} 
      />
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 1,
            color: COLORS.text.primary,
            fontWeight: 600
          }}
        >
          {title}
        </Typography>
        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const OptimalSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    // Map emoji to icon types
    const emojiToIcon = {
      '⚖️': 'balance',
      '💰': 'money',
      '🤝': 'handshake',
      '📈': 'trending',
      '🏢': 'business',
      '⚙️': 'settings',
      '🔒': 'security',
      '💼': 'work',
      '👥': 'groups',
      '📚': 'school'
    };
    
    return {
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      aspects: Array.from(doc.querySelectorAll('.aspect-card')).map(card => ({
        iconType: emojiToIcon[card.querySelector('.icon')?.textContent?.trim()] || 'balance',
        title: card.querySelector('.aspect-title')?.textContent || '',
        description: card.querySelector('.aspect-content p')?.textContent || ''
      })),
      benefits: Array.from(doc.querySelectorAll('.benefit-item')).map(item => ({
        title: item.querySelector('h4')?.textContent || '',
        description: item.querySelector('p')?.textContent || '',
        icon: getIconByTitle(item.querySelector('h4')?.textContent || '')
      }))
    };
  };

  const getIconByTitle = (title) => {
    const titleLower = title.toLowerCase();
    if (titleLower.includes('økonomisk')) return SecurityIcon;
    if (titleLower.includes('oppdrag')) return WorkIcon;
    if (titleLower.includes('medeierskap')) return GroupsIcon;
    if (titleLower.includes('faglig')) return SchoolIcon;
    return SecurityIcon;
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content optimal"
      id="optimal-konsulenttilvaerelse"
    >
      <Container maxWidth="lg" sx={{ pt: 0, pb: { xs: 4, md: 6 } }}>
        <SectionHeader title={menuItem?.title || parsedContent.sectionTitle} />
        
        {/* Hero Section */}
        <Box sx={{ textAlign: 'center', mb: 10 }}>
          <Typography 
            variant="h2" 
            className="main-title"
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              color: COLORS.text.primary,
              mb: 2,
              maxWidth: '900px',
              mx: 'auto'
            }}
          >
            {parsedContent.title}
          </Typography>
          <Typography 
            variant="h5"
            className="subtitle"
            sx={{ 
              color: COLORS.text.secondary,
              fontWeight: 400,
              maxWidth: '800px',
              mx: 'auto',
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            {parsedContent.subtitle}
          </Typography>
        </Box>

        {/* Aspects Grid with unique layout */}
        <Grid container spacing={4} sx={{ mb: 10 }}>
          {/* Primary aspects - larger cards */}
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              {parsedContent.aspects.slice(0, 3).map((aspect, index) => (
                <Grid item xs={12} key={index}>
                  <AspectCard {...aspect} variant="primary" />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Secondary aspects - smaller cards */}
          <Grid item xs={12} md={5}>
            <Grid container spacing={4}>
              {parsedContent.aspects.slice(3).map((aspect, index) => (
                <Grid item xs={12} key={index + 3}>
                  <AspectCard {...aspect} variant="secondary" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Benefits section with new style */}
        <Box
          component="section"
          sx={{
            background: `linear-gradient(135deg, ${COLORS.primary.main}05, ${COLORS.primary.light}08)`,
            borderRadius: 4,
            p: { xs: 3, md: 5 }
          }}
        >
          <Typography 
            variant="h3" 
            sx={{ 
              mb: 6,
              textAlign: 'center',
              color: COLORS.text.primary,
              fontWeight: 600,
              fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.125rem' }
            }}
          >
            Fordeler hos oss
          </Typography>
          <Box>
            {parsedContent.benefits.map((benefit, index) => (
              <BenefitItem key={index} {...benefit} />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OptimalSection; 