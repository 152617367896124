import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress, Container, Alert, Button } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';
import styled from '@emotion/styled';
import KonseptSection from '../sections/KonseptSection';
import VarEkspertiseSection from '../sections/VarEkspertiseSection';
import OkonomiskSection from '../sections/OkonomiskSection';
import LidenskapSection from '../sections/LidenskapSection';
import FolkaSection from '../sections/FolkaSection';
import OpdragSection from '../sections/OpdragSection';
import OptimalSection from '../sections/OptimalSection';
import HvorforVelgeOssSection from '../sections/HvorforVelgeOssSection';
import CtaSection from '../sections/CtaSection';
import ContentErrorFallback from './ContentErrorFallback';
import apiService from '../../services/apiService';

const StyledSection = styled(Box)(({ theme }) => ({
  '& .section-header': {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    '& .section-title': {
      fontSize: '2.5rem',
      fontWeight: 600,
      color: COLORS.text.primary,
      marginBottom: theme.spacing(2)
    },
    '& .section-subtitle': {
      fontSize: '1.25rem',
      color: COLORS.text.secondary
    }
  },

  '& .highlight-box': {
    background: `linear-gradient(135deg, ${COLORS.primary.light}, ${COLORS.primary.main})`,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    '& .welcome-text': {
      color: 'white',
      fontSize: '1.5rem',
      fontWeight: 500
    }
  },

  '& .benefits-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(6),
    '& .benefit-card': {
      padding: theme.spacing(3),
      borderRadius: theme.spacing(2),
      background: COLORS.background.paper,
      boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)'
      }
    },
    '& .benefit-icon': {
      fontSize: '2rem',
      marginBottom: theme.spacing(2)
    }
  },

  '& .features-list': {
    marginBottom: theme.spacing(6),
    '& .feature-item': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& .feature-check': {
        color: COLORS.primary.main,
        marginRight: theme.spacing(2),
        fontSize: '1.25rem'
      }
    }
  },

  '& .cta-box': {
    background: COLORS.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    textAlign: 'center',
    '& .cta-title': {
      fontSize: '1.75rem',
      marginBottom: theme.spacing(3)
    },
    '& .highlight': {
      color: COLORS.primary.main,
      fontWeight: 600
    },
    '& .contact-button': {
      marginTop: theme.spacing(3),
      padding: theme.spacing(1.5, 4),
      fontSize: '1.1rem'
    }
  },

  '& .section-content': {
    minHeight: 'auto',
    height: 'auto'
  }
}));

const StyledContent = styled(Box)(({ theme }) => ({
  '& .hero-section': {
    textAlign: 'center',
    marginBottom: '3rem',
    '& .main-title': {
      fontSize: { xs: '2rem', md: '3rem' },
      color: COLORS.text.primary,
      fontWeight: 600,
      marginBottom: '1rem'
    },
    '& .subtitle': {
      fontSize: { xs: '1.1rem', md: '1.3rem' },
      color: COLORS.text.secondary,
      maxWidth: '800px',
      margin: '0 auto'
    }
  },

  '& .welcome-banner': {
    background: COLORS.primary.main,
    color: 'white',
    padding: '2rem',
    borderRadius: '12px',
    textAlign: 'center',
    marginBottom: '4rem',
    '& p': {
      fontSize: '1.5rem',
      fontWeight: 500,
      margin: 0
    }
  },

  '& .benefits-container': {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: 'repeat(3, 1fr)'
    },
    gap: '2rem',
    marginBottom: '4rem'
  },

  '& .benefit-card': {
    background: 'white',
    padding: '2rem',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)'
    },
    '& .card-icon': {
      fontSize: '2.5rem',
      marginBottom: '1rem'
    },
    '& h3': {
      fontSize: '1.25rem',
      color: COLORS.text.primary,
      marginBottom: '1rem'
    },
    '& p': {
      color: COLORS.text.secondary,
      marginBottom: '1rem'
    },
    '& .learn-more': {
      color: COLORS.primary.main,
      textDecoration: 'none',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },

  '& .features-section': {
    maxWidth: '800px',
    margin: '0 auto',
    '& .feature-item': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginBottom: '1rem',
      '& .check-icon': {
        color: COLORS.primary.main,
        fontSize: '1.25rem'
      },
      '& p': {
        color: COLORS.text.secondary,
        margin: 0
      }
    }
  }
}));

// Simplified background alternation
const getSectionBackground = (index) => ({
  background: index % 2 === 0 ? '#F4F2EE' : '#FFFFFF'
});

const StyledPage = styled('main')(({ theme }) => ({
  '& .section-content': {
    width: '100%',
    position: 'relative',
    padding: 0,
    '& > .MuiContainer-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
      }
    }
  }
}));

const StyledFooter = styled('footer')(({ theme }) => ({
  '& .copyright': {
    textAlign: 'center',
    color: COLORS.text.secondary,
    fontSize: '0.875rem',
    padding: theme.spacing(0.625), // 5px in MUI spacing
    opacity: 0.8
  }
}));

const Page = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const sectionRefs = useRef({});
  const [activeSection, setActiveSection] = useState('');
  const [showContactForm, setShowContactForm] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      try {
        setLoading(true);
        
        // Check if the user is online
        if (!navigator.onLine) {
          console.log('User is offline, attempting to use cached data');
        }
        
        // Use the cached API service
        const response = await apiService.getMenuItems();
        
        if (response.success) {
          const sortedSections = response.data.sort((a, b) => a.order_index - b.order_index);
          
          if (sortedSections.length === 0) {
            setError('No content found. Please contact the administrator.');
            return;
          }
          
          setSections(sortedSections);
          sortedSections.forEach(section => {
            sectionRefs.current[section.slug] = React.createRef();
          });
        } else {
          console.error('Failed to load content:', response.error);
          setError(`Failed to load content: ${response.error || 'Unknown error'}`);
        }
      } catch (err) {
        console.error('Error loading content:', err);
        
        // More detailed error message based on the error type
        let errorMessage = 'Failed to load content. ';
        
        if (err.response) {
          errorMessage += `Server responded with error ${err.response.status}: ${err.response.data.error || 'Unknown error'}`;
        } else if (err.request) {
          errorMessage += 'No response received from server. Please check your connection or try again later.';
        } else {
          errorMessage += err.message || 'Unknown error occurred';
        }
        
        if (retryCount < MAX_RETRIES) {
          console.log(`Retrying (${retryCount + 1}/${MAX_RETRIES})...`);
          setRetryCount(prev => prev + 1);
        } else {
          setError(errorMessage + ' Please refresh the page or try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [retryCount]);

  useEffect(() => {
    // Only scroll if there's a hash in the URL
    const { hash } = window.location;
    if (hash && sectionRefs.current[hash.slice(1)]) {
      sectionRefs.current[hash.slice(1)].current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [sections]);

  useEffect(() => {
    // Set up intersection observer
    const observerOptions = {
      root: null,
      rootMargin: '-100px 0px', // Adjust based on your header height
      threshold: 0
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Observe all sections
    Object.values(sectionRefs.current).forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, [sections]);

  const renderSection = (section) => {
    switch(section.slug) {
      case 'konseptet':
        return <KonseptSection content={section.content} menuItem={section} />;
      case 'var-ekspertise':
        return <VarEkspertiseSection content={section.content} menuItem={section} />;
      case 'okonomisk-modell':
        return <OkonomiskSection content={section.content} menuItem={section} />;
      case 'var-lidenskap':
        return <LidenskapSection content={section.content} menuItem={section} />;
      case 'folka':
        return <FolkaSection content={section.content} menuItem={section} />;
      case 'oppdrag':
        return <OpdragSection content={section.content} menuItem={section} />;
      case 'optimal-konsulenttilvaerelse':
        return <OptimalSection content={section.content} menuItem={section} />;
      case 'hvorfor-velge-oss':
        return <HvorforVelgeOssSection content={section.content} menuItem={section} />;
      default:
        return <div dangerouslySetInnerHTML={{ __html: section.content }} />;
    }
  };

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  return (
    <Box className="page-container">
      {loading ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '50vh',
          flexDirection: 'column'
        }}>
          <CircularProgress size={60} thickness={4} sx={{ color: COLORS.primary.main }} />
          <Typography variant="body1" sx={{ mt: 3, color: COLORS.text.secondary }}>
            Loading content...
          </Typography>
        </Box>
      ) : error ? (
        <ContentErrorFallback error={error} />
      ) : (
        <Box sx={{ 
          '& > div:first-of-type': { // Target first section
            pt: 0 // Remove top padding from first section
          }
        }}>
          {sections.map((section, index) => (
            <Box
              key={section.id}
              ref={sectionRefs.current[section.slug]}
              id={section.slug}
              sx={{
                width: '100%',
                pt: { xs: 6, md: 12 },
                pb: { xs: 2, md: 3 },
                ...getSectionBackground(index),
                transition: 'background-color 0.3s ease',
                ...(index === 0 && {
                  pt: 0,
                  mt: 0
                })
              }}
            >
              <Container 
                maxWidth="lg"
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {renderSection(section)}
              </Container>
            </Box>
          ))}
          <CtaSection onContactClick={handleContactClick} />
        </Box>
      )}
    </Box>
  );
};

export default Page;
