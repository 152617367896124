import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  Favorite as HeartIcon,
  People as TeamIcon,
  Rocket as RocketIcon,
  GpsFixed as TargetIcon,
  Lightbulb as LightbulbIcon,
  Park as SustainabilityIcon,
  Favorite as IntegrityIcon,
  Autorenew as ContinuousIcon,
  Handshake as SharingIcon
} from '@mui/icons-material';

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

// Wrap Paper component with motion
const AnimatedCard = motion.create(Paper);
const AnimatedBox = motion.create(Box);

const PassionCard = ({ iconType, title, description }) => {
  const IconComponent = {
    'heart': HeartIcon,
    'handshake': TeamIcon,
    'rocket': RocketIcon
  }[iconType] || HeartIcon;

  return (
    <AnimatedCard
      elevation={0}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, margin: "-100px" }}
      variants={fadeInUp}
      sx={{
        p: 4,
        height: '100%',
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
        transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 12px 30px rgba(0,0,0,0.12)',
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          }
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '4px',
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          opacity: 0,
          transition: 'opacity 0.3s ease',
        },
        '&:hover::before': {
          opacity: 1
        }
      }}
    >
      <IconComponent 
        sx={{ 
          fontSize: 48, 
          color: COLORS.primary.main,
          mb: 3,
          transition: 'all 0.3s ease',
        }} 
      />
      <Typography 
        variant="h5" 
        gutterBottom
        sx={{ 
          fontWeight: 600,
          color: COLORS.text.primary,
          mb: 2
        }}
      >
        {title}
      </Typography>
      <Typography 
        sx={{ 
          color: COLORS.text.secondary,
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
    </AnimatedCard>
  );
};

const VisionItem = ({ icon, title, description }) => {
  const getIconComponent = (iconName) => {
    const icons = {
      '🎯': TargetIcon,
      '💡': LightbulbIcon,
      '🌱': SustainabilityIcon
    };
    const IconComponent = icons[iconName] || TargetIcon;
    return (
      <Box
        sx={{
          width: 44,
          height: 44,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `linear-gradient(135deg, ${COLORS.primary.light}15, ${COLORS.primary.main}08)`,
          flexShrink: 0,
          mb: 0
        }}
      >
        <IconComponent 
          sx={{ 
            fontSize: 24,
            color: COLORS.primary.main,
            transition: 'all 0.3s ease'
          }} 
        />
      </Box>
    );
  };

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        gap: 3,
        mb: 3,
        '&:last-child': { mb: 0 },
        '&:hover': {
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          }
        }
      }}
    >
      {getIconComponent(icon)}
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 0.5,
            fontWeight: 600,
            fontSize: { xs: '1rem', md: '1.1rem' },
            color: COLORS.text.primary
          }}
        >
          {title}
        </Typography>
        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            fontSize: '0.95rem',
            lineHeight: 1.5
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const ValueItem = ({ icon, title, description }) => {
  const getIconComponent = (iconName) => {
    const icons = {
      '🤍': IntegrityIcon,
      '🔄': ContinuousIcon,
      '🤲': SharingIcon
    };
    const IconComponent = icons[iconName] || IntegrityIcon;
    return (
      <Box
        sx={{
          width: 44,
          height: 44,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `linear-gradient(135deg, ${COLORS.primary.light}15, ${COLORS.primary.main}08)`,
          flexShrink: 0,
          mb: 0
        }}
      >
        <IconComponent 
          sx={{ 
            fontSize: 24,
            color: COLORS.primary.main,
            transition: 'all 0.3s ease'
          }} 
        />
      </Box>
    );
  };

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        display: 'flex',
        gap: 3,
        mb: 3,
        '&:last-child': { mb: 0 },
        '&:hover': {
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: COLORS.primary.dark
          }
        }
      }}
    >
      {getIconComponent(icon)}
      <Box>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 0.5,
            fontWeight: 600,
            fontSize: { xs: '1rem', md: '1.1rem' },
            color: COLORS.text.primary
          }}
        >
          {title}
        </Typography>
        <Typography 
          sx={{ 
            color: COLORS.text.secondary,
            fontSize: '0.95rem',
            lineHeight: 1.5
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: { xs: 4, md: 6 }
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 1,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const TimelineItem = ({ icon, title, description }) => (
  <Box
    component={motion.div}
    variants={fadeInUp}
    sx={{
      position: 'relative',
      pl: { xs: 3, md: 4 },
      pb: 3,
      '&:last-child': {
        pb: 0,
        '&::before': {
          display: 'none'
        }
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        left: { xs: '8px', md: '10px' },
        top: '26px',
        bottom: 0,
        width: '1px',
        background: `${COLORS.primary.main}15`,
      }
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        left: { xs: '-1px', md: 0 },
        top: '4px',
        width: { xs: '18px', md: '20px' },
        height: { xs: '18px', md: '20px' },
        borderRadius: '50%',
        background: `${COLORS.primary.main}10`,
        border: `1px solid ${COLORS.primary.main}30`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px'
      }}
    >
      {icon}
    </Box>
    <Typography 
      variant="subtitle1" 
      sx={{ 
        fontWeight: 600,
        color: COLORS.text.primary,
        mb: 0.5,
        fontSize: { xs: '1rem', md: '1.1rem' }
      }}
    >
      {title}
    </Typography>
    <Typography 
      variant="body2"
      sx={{ 
        color: COLORS.text.secondary,
        lineHeight: 1.5,
        fontSize: { xs: '0.875rem', md: '0.9rem' }
      }}
    >
      {description}
    </Typography>
  </Box>
);

const LidenskapSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    // Map emoji to icon types
    const emojiToIcon = {
      '❤️': 'heart',
      '🤝': 'handshake',
      '🚀': 'rocket',
      '🎯': 'target',
      '💡': 'lightbulb',
      '🌱': 'eco',
      '🤍': 'heart-outline',
      '🔄': 'refresh',
      '🤲': 'diversity'
    };
    
    return {
      title: doc.querySelector('.hero-title .main-title')?.textContent || '',
      subtitle: doc.querySelector('.hero-title .subtitle')?.textContent || '',
      passionPoints: Array.from(doc.querySelectorAll('.passion-card')).map(card => ({
        iconType: emojiToIcon[card.querySelector('.icon')?.textContent?.trim()] || 'heart',
        title: card.querySelector('.passion-title')?.textContent || '',
        description: card.querySelector('.passion-content p')?.textContent || ''
      })),
      vision: {
        title: doc.querySelector('.vision-section .section-header h2')?.textContent || '',
        items: Array.from(doc.querySelectorAll('.vision-list li')).map(item => ({
          icon: item.querySelector('.icon')?.textContent || '',
          title: item.querySelector('.vision-item h4')?.textContent || '',
          description: item.querySelector('.vision-item p')?.textContent || ''
        }))
      },
      values: {
        title: doc.querySelector('.values-section .section-header h2')?.textContent || '',
        items: Array.from(doc.querySelectorAll('.values-list li')).map(item => ({
          icon: item.querySelector('.icon')?.textContent || '',
          title: item.querySelector('.value-item h4')?.textContent || '',
          description: item.querySelector('.value-item p')?.textContent || ''
        }))
      }
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content lidenskap"
      id="var-lidenskap"
      sx={{
        width: '100%',
        pb: { xs: 6, md: 8 }
      }}
    >
      {/* Section Title */}
      <SectionHeader title={menuItem?.title || 'VÅR LIDENSKAP'} />

      {/* Hero Section */}
      <Box component="header" className="hero-section" sx={{ textAlign: 'center', mb: 8 }}>
        <Typography 
          variant="h2" 
          className="main-title"
          sx={{ 
            fontSize: { xs: '2rem', md: '3rem' },
            fontWeight: 700,
            color: COLORS.text.primary,
            mb: 2,
            maxWidth: '900px',
            mx: 'auto'
          }}
        >
          {parsedContent.title}
        </Typography>
        <Typography 
          variant="h5"
          className="subtitle"
          sx={{ 
            color: COLORS.text.secondary,
            fontWeight: 400,
            maxWidth: '800px',
            mx: 'auto',
            fontSize: { xs: '1.1rem', md: '1.25rem' }
          }}
        >
          {parsedContent.subtitle}
        </Typography>
      </Box>

      {/* Passion Points Grid */}
      <Box component="section" className="passion-points" sx={{ mb: 10 }}>
        <Grid container spacing={4}>
          {parsedContent.passionPoints.map((point, index) => (
            <Grid item xs={12} md={4} key={index}>
              <PassionCard {...point} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Vision Section */}
      <Grid container spacing={{ xs: 4, md: 6 }} sx={{ 
        maxWidth: 1100, 
        mx: 'auto',
        px: { xs: 2, md: 0 }
      }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3,
                color: COLORS.text.primary,
                fontWeight: 600,
                position: 'relative',
                pl: { xs: 3, md: 4 },
                fontSize: { xs: '1rem', md: '1.15rem' },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  width: { xs: '20px', md: '24px' },
                  height: '2px',
                  background: COLORS.primary.main,
                  transform: 'translateY(-50%)'
                }
              }}
            >
              {parsedContent.vision.title}
            </Typography>
            {parsedContent.vision.items.map((item, index) => (
              <VisionItem key={index} {...item} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 3,
                color: COLORS.text.primary,
                fontWeight: 600,
                position: 'relative',
                pl: 5,
                fontSize: { xs: '1rem', md: '1.15rem' },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  width: '32px',
                  height: '2px',
                  background: COLORS.primary.main,
                  transform: 'translateY(-50%)'
                }
              }}
            >
              {parsedContent.values.title}
            </Typography>
            {parsedContent.values.items.map((item, index) => (
              <ValueItem key={index} {...item} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LidenskapSection; 