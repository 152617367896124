// Cache duration in milliseconds (e.g., 5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

// Debug flag - set to false to disable console logs
const DEBUG = false;

// Main cache object
const cache = {
  data: {},
  
  // Set item in cache with timestamp
  set: (key, data) => {
    cache.data[key] = {
      timestamp: Date.now(),
      data: data
    };
    
    // Also store in localStorage for persistence across sessions
    try {
      localStorage.setItem(`cache_${key}`, JSON.stringify({
        timestamp: Date.now(),
        data: data
      }));
    } catch (error) {
      console.warn('Failed to store cache in localStorage:', error);
    }
  },
  
  // Get item from cache if it exists and is not expired
  get: (key) => {
    // First try memory cache
    const cachedItem = cache.data[key];
    
    if (cachedItem && (Date.now() - cachedItem.timestamp < CACHE_DURATION)) {
      if (DEBUG) console.log(`Using memory cache for: ${key}`);
      return cachedItem.data;
    }
    
    // Then try localStorage
    try {
      const storedItem = localStorage.getItem(`cache_${key}`);
      if (storedItem) {
        const parsedItem = JSON.parse(storedItem);
        if (Date.now() - parsedItem.timestamp < CACHE_DURATION) {
          if (DEBUG) console.log(`Using localStorage cache for: ${key}`);
          // Update memory cache
          cache.data[key] = parsedItem;
          return parsedItem.data;
        }
      }
    } catch (error) {
      console.warn('Failed to retrieve cache from localStorage:', error);
    }
    
    return null;
  },
  
  // Clear specific cache item
  clear: (key) => {
    delete cache.data[key];
    try {
      localStorage.removeItem(`cache_${key}`);
    } catch (error) {
      console.warn('Failed to remove cache from localStorage:', error);
    }
  },
  
  // Clear all cache
  clearAll: () => {
    cache.data = {};
    try {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('cache_')) {
          localStorage.removeItem(key);
        }
      });
    } catch (error) {
      console.warn('Failed to clear cache from localStorage:', error);
    }
  }
};

// Cache version control
let currentCacheVersion = localStorage.getItem('cache_version') || '0';

export const updateCacheVersion = (newVersion) => {
  if (newVersion && newVersion !== currentCacheVersion) {
    if (DEBUG) console.log(`Cache version changed from ${currentCacheVersion} to ${newVersion}, clearing cache`);
    currentCacheVersion = newVersion;
    localStorage.setItem('cache_version', newVersion);
    cache.clearAll();
    return true;
  }
  return false;
};

export default cache; 