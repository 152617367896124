import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Snackbar, 
  IconButton, 
  Typography,
  Paper,
  Slide
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import { COLORS } from '../../utils/colors';
import apiService from '../../services/apiService';

const DISMISS_KEY = 'pwa_prompt_dismissed';
const DISMISS_DURATION = 90; // Days to wait before showing again

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [enablePwaInstall, setEnablePwaInstall] = useState(true);
  
  // Check if the app is already installed
  const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches || 
                         window.navigator.standalone === true;
  
  // Check if the device is mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  // Check if user has previously dismissed the prompt
  const isDismissed = () => {
    const dismissedValue = localStorage.getItem(DISMISS_KEY);
    if (!dismissedValue) return false;
    
    try {
      const dismissedData = JSON.parse(dismissedValue);
      const dismissedTime = new Date(dismissedData.timestamp);
      const currentTime = new Date();
      
      // Calculate days difference
      const diffTime = currentTime - dismissedTime;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      
      // If less than DISMISS_DURATION days have passed, consider it dismissed
      return diffDays < DISMISS_DURATION;
    } catch (e) {
      // If there's an error parsing, assume it's not dismissed
      return false;
    }
  };

  // Fetch PWA settings
  useEffect(() => {
    const fetchPwaSettings = async () => {
      try {
        const response = await apiService.getPwaSettings();
        if (response.success) {
          setEnablePwaInstall(response.data.enable_pwa_install);
        }
      } catch (error) {
        console.error('Error fetching PWA settings:', error);
        // Default to enabled if there's an error
        setEnablePwaInstall(true);
      }
    };

    fetchPwaSettings();
  }, []);

  useEffect(() => {
    // Check if the prompt should be shown
    const shouldShowPrompt = !isAppInstalled && isMobile && enablePwaInstall && !isDismissed();
    
    if (shouldShowPrompt) {
      // Store the event for later use
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setShowPrompt(true);
        
        // Show banner after a short delay
        setTimeout(() => {
          setShowBanner(true);
        }, 3000);
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    } else {
      // Make sure prompt is hidden if it shouldn't be shown
      setShowPrompt(false);
      setShowBanner(false);
    }
  }, [isAppInstalled, isMobile, enablePwaInstall]);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    // Show the install prompt
    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        // User accepted, permanently dismiss the prompt
        localStorage.setItem(DISMISS_KEY, JSON.stringify({ 
          timestamp: new Date().toISOString(),
          permanent: true
        }));
      } else {
        console.log('User dismissed the install prompt');
        // User dismissed the browser prompt, also dismiss our banner
        handleClose();
      }
      
      // Clear the saved prompt as it can only be used once
      setDeferredPrompt(null);
      setShowBanner(false);
    });
  };

  const handleClose = () => {
    setShowBanner(false);
    setShowPrompt(false);
    
    // Remember that user dismissed the prompt with timestamp
    localStorage.setItem(DISMISS_KEY, JSON.stringify({ 
      timestamp: new Date().toISOString(),
      permanent: false
    }));
    
    console.log('Prompt dismissed and will not show again for', DISMISS_DURATION, 'days');
  };

  // Don't show the prompt if offline or if any condition is not met
  if (!navigator.onLine || !showPrompt || !showBanner || !enablePwaInstall || isDismissed()) return null;

  return (
    <Slide direction="up" in={showBanner}>
      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1300,
          p: 2,
          borderRadius: '12px 12px 0 0',
          backgroundColor: 'white',
          boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
          border: `1px solid ${COLORS.primary.light}`,
          borderBottom: 'none'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Box
              component="img"
              src="/logo.png"
              alt="Pebbles Logo"
              sx={{
                height: '40px',
                width: 'auto',
                mr: 2
              }}
            />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                Installer Pebbles App
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Få en bedre opplevelse på mobilen
              </Typography>
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={handleInstallClick}
              sx={{ mr: 1 }}
            >
              Installer
            </Button>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Slide>
  );
};

export default InstallPrompt; 