import React from 'react';
import { Box, AppBar, Toolbar, Typography, Button, Container, Paper } from '@mui/material';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from '../../utils/axios';
import { COLORS } from '../../utils/colors';

function AdminLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === `/kontrollpanel${path}`;
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout.php');
      localStorage.removeItem('token');
      navigate('/kontrollpanel/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const menuItems = [
    { path: '/dashboard', label: 'Dashboard', icon: <DashboardIcon /> },
    { path: '/menu', label: 'Menu', icon: <MenuIcon /> },
    { path: '/settings', label: 'Settings', icon: <SettingsIcon /> },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar 
        position="sticky" 
        elevation={0}
        sx={{ 
          background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
          borderBottom: `1px solid ${COLORS.primary.dark}`,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography 
            variant="h6" 
            component={Link} 
            to="/kontrollpanel/dashboard"
            sx={{ 
              color: 'white', 
              textDecoration: 'none',
              fontWeight: 600,
              letterSpacing: '0.5px',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            <DashboardIcon sx={{ fontSize: 24 }} />
            Kontrollpanel
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {menuItems.map((item) => (
              <Button
                key={item.path}
                component={Link}
                to={`/kontrollpanel${item.path}`}
                color="inherit"
                sx={{ 
                  px: 2,
                  py: 1,
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  position: 'relative',
                  backgroundColor: isActive(item.path) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.15)'
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 4,
                    right: 4,
                    height: '2px',
                    background: 'white',
                    opacity: isActive(item.path) ? 1 : 0,
                    transform: isActive(item.path) ? 'scaleX(1)' : 'scaleX(0)',
                    transition: 'transform 0.2s ease, opacity 0.2s ease',
                  },
                  '&:hover::after': {
                    opacity: 1,
                    transform: 'scaleX(1)'
                  }
                }}
              >
                {item.icon}
                {item.label}
              </Button>
            ))}
            <Button
              color="inherit"
              onClick={handleLogout}
              sx={{ 
                ml: 2,
                px: 2,
                py: 1,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                border: '1px solid rgba(255, 255, 255, 0.3)',
                transition: 'all 0.2s ease',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  border: '1px solid rgba(255, 255, 255, 0.5)',
                }
              }}
            >
              <LogoutIcon />
              Logg ut
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box 
        sx={{ 
          flex: 1,
          background: `linear-gradient(180deg, ${COLORS.background.default} 0%, ${COLORS.tertiary.light} 100%)`,
          minHeight: '100vh'
        }}
      >
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          <Paper 
            elevation={0}
            sx={{ 
              p: 3,
              borderRadius: 2,
              border: `1px solid ${COLORS.tertiary.main}`,
              background: COLORS.background.paper,
              boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
              position: 'relative',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '3px',
                background: `linear-gradient(to right, ${COLORS.primary.main}, ${COLORS.primary.light})`
              }
            }}
          >
            <Outlet />
          </Paper>
        </Container>
      </Box>

      <Box 
        component="footer" 
        sx={{
          py: 2,
          px: 2,
          textAlign: 'center',
          backgroundColor: COLORS.background.paper,
          borderTop: `1px solid ${COLORS.tertiary.main}`
        }}
      >
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            fontWeight: 500,
            fontSize: '0.875rem'
          }}
        >
          © {new Date().getFullYear()} Pebbles Admin. Alle rettigheter forbeholdt.
        </Typography>
      </Box>
    </Box>
  );
}

export default AdminLayout;
