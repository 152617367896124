import axios from 'axios';

const isDevelopment = window.location.hostname === 'localhost';

const instance = axios.create({
  baseURL: isDevelopment ? 'http://localhost:8000' : 'https://pebbles.no',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance;
