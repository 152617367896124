import React from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, Container } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { decodeHtmlEntities } from '../../utils/htmlUtils';
import { motion } from 'framer-motion';
import { 
  AccountBalance as AccountBalanceIcon,
  Assessment as AssessmentIcon,
  Diamond as DiamondIcon,
  Shield as ShieldIcon,
  AutoAwesome as AutoAwesomeIcon,
  Description as DescriptionIcon,
  Star as StarIcon
} from '@mui/icons-material';

// Animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99]
    }
  }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

// Wrap Paper component with motion
const AnimatedCard = motion.create(Paper);
const AnimatedBox = motion.create(Box);

const HighlightCard = ({ iconType, title, description }) => {
  const IconComponent = {
    'money': AccountBalanceIcon,
    'chart': AssessmentIcon,
    'diamond': DiamondIcon
  }[iconType] || AccountBalanceIcon;

  return (
    <AnimatedCard
      elevation={0}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, margin: "-100px" }}
      variants={fadeInUp}
      sx={{
        p: 4,
        height: '100%',
        borderRadius: '24px',
        background: 'white',
        position: 'relative',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          background: `linear-gradient(135deg, ${COLORS.primary.main}08, ${COLORS.primary.light}08)`,
          borderRadius: 'inherit',
          opacity: 0,
          transition: 'opacity 0.3s ease'
        },
        '&:hover': {
          transform: 'translateY(-6px)',
          '&::before': {
            opacity: 1
          },
          '& .icon-wrapper': {
            background: COLORS.primary.main,
            '& svg': {
              color: 'white',
              transform: 'rotate(10deg) scale(1.1)'
            }
          }
        }
      }}
    >
      <Box
        className="icon-wrapper"
        sx={{
          width: 72,
          height: 72,
          borderRadius: '16px',
          background: `${COLORS.primary.main}12`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 3,
          transition: 'all 0.3s ease',
          '& svg': {
            fontSize: 36,
            color: COLORS.primary.main,
            transition: 'all 0.3s ease'
          }
        }}
      >
        <IconComponent />
      </Box>
      <Typography 
        variant="h5" 
        gutterBottom
        sx={{ 
          fontWeight: 600,
          color: COLORS.text.primary,
          mb: 2
        }}
      >
        {title}
      </Typography>
      <Typography 
        sx={{ 
          color: COLORS.text.secondary,
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
    </AnimatedCard>
  );
};

const DetailItem = ({ icon, title, description, subItems }) => {
  const IconComponent = {
    'shield': ShieldIcon,
    'star': AutoAwesomeIcon,
    'description': DescriptionIcon,
    'sparkle': StarIcon
  }[icon] || StarIcon;

  return (
    <Box
      component={motion.div}
      variants={fadeInUp}
      sx={{
        position: 'relative',
        pl: 5,
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '16px',
          top: 0,
          bottom: 0,
          width: '2px',
          background: `linear-gradient(to bottom, ${COLORS.primary.main}20, ${COLORS.primary.main}05)`,
          borderRadius: '1px'
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '34px',
          height: '34px',
          borderRadius: '50%',
          background: `linear-gradient(135deg, ${COLORS.primary.main}15, ${COLORS.primary.main}05)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <IconComponent 
          sx={{ 
            fontSize: 20,
            color: COLORS.primary.main
          }} 
        />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.1rem',
            fontWeight: 600,
            color: COLORS.text.primary,
            mb: 1
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body2"
          sx={{ 
            color: COLORS.text.secondary,
            lineHeight: 1.6,
            mb: subItems ? 1 : 0
          }}
        >
          {description}
        </Typography>
        {subItems && (
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {subItems.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    py: 0.5
                  }}
                >
                  <Box
                    sx={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      bgcolor: COLORS.primary.main,
                      flexShrink: 0
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: COLORS.text.secondary,
                      fontSize: '0.9rem'
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

const SectionHeader = ({ title }) => (
  <Box sx={{ 
    textAlign: 'center',
    mb: { xs: 4, md: 6 }
  }}>
    <Typography 
      variant="overline" 
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      sx={{ 
        color: COLORS.primary.main,
        fontWeight: 600,
        letterSpacing: 1.5,
        mb: 1,
        display: 'block'
      }}
    >
      {title}
    </Typography>
  </Box>
);

const InfoBlock = ({ title, content }) => (
  <Box
    sx={{
      mb: 6,
      p: 4,
      borderRadius: 2,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        background: `linear-gradient(to bottom, ${COLORS.primary.main}, ${COLORS.primary.light}40)`,
        borderRadius: '4px'
      }
    }}
  >
    <Typography 
      variant="h4" 
      sx={{ 
        mb: 3,
        fontSize: { xs: '1.5rem', md: '1.75rem' },
        fontWeight: 600,
        color: COLORS.text.primary
      }}
    >
      {title}
    </Typography>
    {content}
  </Box>
);

const PointsGrid = ({ points }) => (
  <Grid container spacing={2} sx={{ mb: 3 }}>
    {points.map((point, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Box
          sx={{
            p: 2,
            bgcolor: `${COLORS.primary.main}08`,
            borderRadius: 1,
            height: '100%'
          }}
        >
          <Typography variant="body2" sx={{ color: COLORS.text.secondary }}>
            {point}
          </Typography>
        </Box>
      </Grid>
    ))}
  </Grid>
);

const OkonomiskSection = ({ content, menuItem }) => {
  const parseContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodeHtmlEntities(htmlContent), 'text/html');
    
    // Map emoji to icon types
    const emojiToIcon = {
      '💰': 'money',
      '📊': 'chart',
      '💎': 'diamond'
    };
    
    return {
      title: doc.querySelector('.main-title')?.textContent || '',
      subtitle: doc.querySelector('.subtitle')?.textContent || '',
      highlights: Array.from(doc.querySelectorAll('.highlight-card')).map(card => ({
        iconType: emojiToIcon[card.querySelector('.icon')?.textContent?.trim()] || 'money',
        title: card.querySelector('.highlight-title')?.textContent || '',
        description: card.querySelector('.highlight-content p')?.textContent || ''
      })),
      benefits: {
        title: doc.querySelector('.benefits-info h2')?.textContent || 'Fordeler',
        items: Array.from(doc.querySelectorAll('.benefits-info .details-list li')).map(li => 
          li.textContent || ''
        )
      }
    };
  };

  const parsedContent = parseContent(content);

  return (
    <Box 
      component="article" 
      className="section-content okonomisk"
      id="okonomisk-modell"
    >
      {/* Section Title */}
      <SectionHeader title={menuItem?.title || 'ØKONOMISK MODELL'} />

      {/* Hero Section */}
      <Box component="header" className="hero-section" sx={{ 
        textAlign: 'center', 
        mb: 12,
        pt: { xs: 8, md: 12 },
        pb: { xs: 12, md: 16 },
        background: `linear-gradient(135deg, ${COLORS.primary.main}08, ${COLORS.primary.light}12)`,
        borderRadius: { xs: 0, md: '60px 60px 60px 60px' },
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '0',
          right: '0',
          height: '60%',
          background: `linear-gradient(90deg, ${COLORS.primary.main}05 1px, transparent 1px),
                      linear-gradient(0deg, ${COLORS.primary.main}05 1px, transparent 1px)`,
          backgroundSize: '20px 20px',
          transform: 'translateY(-50%) rotate(-5deg)',
          opacity: 0.5
        }
      }}>
        <Container maxWidth="md" sx={{ position: 'relative' }}>
          <Typography 
            variant="h2" 
            className="main-title"
            sx={{ 
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              color: COLORS.text.primary,
              mb: 3,
              lineHeight: 1.2,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '80px',
                height: '4px',
                background: COLORS.primary.main,
                borderRadius: '2px'
              }
            }}
          >
            {parsedContent.title}
          </Typography>
          <Typography 
            variant="h5"
            className="subtitle"
            sx={{ 
              color: COLORS.text.secondary,
              fontWeight: 400,
              lineHeight: 1.6,
              maxWidth: '800px',
              mx: 'auto',
              fontSize: { xs: '1.1rem', md: '1.25rem' },
            }}
          >
            {parsedContent.subtitle}
          </Typography>
        </Container>
      </Box>

      {/* Highlights Grid */}
      <Box component="section" className="economic-highlights" sx={{ mb: 10 }}>
        <Grid container spacing={4}>
          {parsedContent.highlights.map((highlight, index) => (
            <Grid item xs={12} md={4} key={index}>
              <HighlightCard {...highlight} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Details Sections */}
      <Box 
        sx={{ 
          maxWidth: 900, 
          mx: 'auto', 
          px: 3,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: '-20px -100px',
            background: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 0L60 30L30 60L0 30L30 0Z' fill='%23${COLORS.primary.main.slice(1)}10'/%3E%3C/svg%3E") repeat`,
            opacity: 0.1,
            zIndex: -1
          }
        }}
      >
        {/* <InfoBlock
          title={parsedContent.benefits.title}
          content={
            <Box>
              <PointsGrid points={parsedContent.benefits.items} />
            </Box>
          }
        /> */}
      </Box>
    </Box>
  );
};

export default OkonomiskSection; 