import React from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../utils/colors';
import ContactForm from '../shared/ContactForm';

const MobileContactDialog = ({ open, onClose, onSuccess }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          background: COLORS.background.paper,
          margin: '32px',
          maxWidth: '400px',
          borderRadius: 2,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: `linear-gradient(to right, ${COLORS.primary.main}, ${COLORS.primary.light})`
          }
        }
      }}
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '1.1rem'
      }}>
        Interessert? Ta kontakt!
        <IconButton onClick={onClose} size="small">
          <CloseIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 2, pb: 2 }}>
        <ContactForm 
          onSuccess={(msg, severity) => {
            onSuccess(msg, severity);
            onClose();
          }} 
          onClose={onClose} 
        />
      </DialogContent>
    </Dialog>
  );
};

export default MobileContactDialog; 