// Brand Colors
export const COLORS = {
    // Primary Colors
    primary: {
        main: '#A71C43',     // Logo Pink - Primary Brand Color
        light: '#B73355',    // Light Pink - For Hover States
        dark: '#8F1839',     // Dark Pink - For Active States
    },

    // Secondary Colors
    secondary: {
        main: '#F4F2EE',     // LinkedIn Beige - Secondary Brand Color
        light: '#FFFFFF',    // White - For Backgrounds
        dark: '#E7E5E1',     // Dark Beige - For Hover States
    },

    // Tertiary Colors
    tertiary: {
        main: '#F6E5EB',     // Soft Pink - Tertiary Brand Color
        light: '#FFF0F4',    // Lighter Pink - For Hover States
        dark: '#EFDAE1',     // Dark Soft Pink - For Active States
    },

    // Text Colors
    text: {
        primary: '#333333',   // Dark Gray - Primary Text
        secondary: '#666666', // Medium Gray - Secondary Text
        light: '#999999',     // Light Gray - Disabled Text
    },

    // Background Colors
    background: {
        default: '#F4F2EE',  // LinkedIn Beige - Default Background
        paper: '#FFFFFF',    // White - Paper/Card Background
        dark: '#F6E5EB',     // Soft Pink - Dark Mode Background
    },

    // Status Colors
    status: {
        success: '#4CAF50',  // Green - Success States
        error: '#f44336',    // Red - Error States
        warning: '#ff9800',  // Orange - Warning States
        info: '#2196f3',     // Blue - Info States
    }
};

// Usage Examples:
// import { COLORS } from '../utils/colors';
// 
// Logo Pink: COLORS.primary.main (#A71C43)
// LinkedIn Beige: COLORS.secondary.main (#F4F2EE)
// Soft Pink: COLORS.tertiary.main (#F6E5EB)
// Text Color: COLORS.text.primary (#333333)
// Background: COLORS.background.default (#F4F2EE) 